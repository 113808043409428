import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import API_URL from "../../config";
import { getAdmin, removeUserSession } from "../../utils/Common";
import ModalDelete from "../ModalDelete";
import { Spinner } from "react-bootstrap";

console.log(getAdmin());
const adminDetails = getAdmin();
function ProfileSetings() {
  const navigate = useNavigate();
  //   const { id } = useParams();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState();
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState("Online");
  const [err, setErr] = useState(null);
  const [style, setStyle] = useState("text-success");
  const url = `${API_URL}/admin/single`;
  const url3 = `${API_URL}/admin/update`;
  useEffect(() => {
    setLoading(true);
    axios.post(url, { id: adminDetails?._id }).then(({ data }) => {
      setUser(data?.payload[0]);
      setLoading(false);
      console.log(data);
    });
  }, [url]);
  //   let style;

  function getStatus() {
    const statusDisplay = document.getElementById("status");
    statusDisplay.textContent = navigator.onLine ? "Online" : "Offline";
    if (statusDisplay.textContent === "Online") {
      setStatus("Online");
      //   console.log("Online");
      setStyle("text-success");
    } else if (statusDisplay.textContent === "Offline") {
      //   console.log("Offline");
      setStyle("text-danger");
      setStatus("Offline");
    }
    axios
      .put(url3, {
        id: adminDetails?._id,
        activeStatus: status,
      })
      .then(({ data }) => {
        // setMessage(data?.message);
        setLoading(false);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        // console.log(err?.response?.data?.message);
        setLoading(false);
        // setShow(true);
      });
  }
  setInterval(getStatus, 200000);

  const handleLogOut = () => {
    setLoading(true);
    // removeUserSession();
    setTimeout(() => {
      removeUserSession(), navigate("/auth");
    }, 3000);
    // navigate("/auth");
  };

  return (
    <div className="tab-pane fade show active profile-overview" id="settings">
      {loading ? (
        <div className="text-center mt-5 pt-5">
          <Spinner animation="border" className="text-center" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          {err && show ? (
            <div
              class="alert alert-danger text-center container w-50"
              role="alert"
            >
              <strong>{err}</strong>
            </div>
          ) : (
            <div style={{ display: "none" }}>{err}</div>
          )}

          {message ? (
            <div class="alert alert-success text-center " role="alert">
              <strong>{message}</strong>
              <p style={{ display: "none" }}>
                {setTimeout(() => navigate("/profile"), 3000)}
              </p>
            </div>
          ) : (
            <div></div>
          )}
          <h2>
            Active Status:
            <i class={`fa fa-dot-circle-o ${style}`} aria-hidden="true"></i>
            <span id="status">Online</span>
          </h2>
          <button
            className="btn btn-danger"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            Delete Account
          </button>

          <ModalDelete
            title="Delete Account"
            writeup="Are you sure you want to delete your account permanenly"
            id={adminDetails?._id}
            url={`${API_URL}/admin/delete/`}
            setMessage={setMessage}
            setLoading={setLoading}
            setErr={setErr}
            logout={true}
          />
        </>
      )}
    </div>
  );
}

export default ProfileSetings;
