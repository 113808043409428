import React, { useState, useEffect } from "react";
import { handleMoving } from "../handles";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import API_URL from "../../../config";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
// import ReactExport from "react-export-excel";
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import ExcelExport from "react-html-table-to-excel";

// import "../../../App.css"
// import "./style.css"
function ServicesProduct() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [category, setCategory] = useState(null);
  const [message, setMessage] = useState(null);
  const [err, setErr] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const url = `${API_URL}/services/${id}`;
  useEffect(() => {
    setLoading(true);
    axios.get(url).then(({ data }) => {
      setCategory(data?.payload[0]);
      setLoading(false);
      // console.log(data)
    });
  }, [url]);

  const calculateApplication = category?.services?.reduce((prev, cur) => {
    // console.log(prev + cur?.totalcost);
    return prev + cur?.applicationfee;
  }, 0);

  const calculatePayable = category?.product?.reduce((prev, cur) => {
    // console.log(prev + cur?.totalcost);
    return prev + cur?.totalcost;
  }, 0);
  const calculatePaid = category?.product?.reduce((prev, cur) => {
    // console.log(prev + cur?.totalcost);
    return prev + cur?.amountpaid;
  }, 0);
  const calculateOutstanding = category?.product?.reduce((prev, cur) => {
    // console.log(prev + cur?.totalcost);
    return prev + cur?.outstanding;
  }, 0);

  const naira = "\u20A6";

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Services Product</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">Products</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}
        <button className="btn btn-primary" onClick={() => navigate(-1)}>
          Go back
        </button>
        <section className="section profile p-5 table-responsive">
          <div className="pb-4">
            <Link
              to={"/create-services-product/" + id}
              className="btn btn-primary"
            >
              create services product
            </Link>
          </div>

          <table class="table table-responsive" id="emp-table">
            <thead>
              <tr>
                <th scope="col">S/N</th>
                <th scope="col">Service Type</th>
                <th scope="col">Description No</th>
                <th scope="col">Total Cost</th>
                <th scope="col">Amount Paid</th>
                <th scope="col">Outstanding</th>
                <th scope="col">Project Duration</th>
                <th scope="col">Edit</th>
                <th scope="col">View</th>
                <th scope="col">Update</th>
              </tr>
            </thead>
            {loading ? (
              <div className="text-center mt-5 pt-5">
                <Spinner
                  animation="border"
                  className="text-center"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                <tbody>
                  {category?.product?.length === 0 ? (
                    <>
                      <h5 className="text-center p-4">
                        No Services Product uploaded
                      </h5>
                    </>
                  ) : (
                    <>
                      {category?.product?.map((data) => {
                        console.log(data);
                        return (
                          <tr>
                            <th scope="row">*</th>
                            <td>{data?.servicetype}</td>
                            {/* <td>{data?.schoolstatus}</td> */}
                            <td>{data?.description}</td>
                            <td>{data?.totalcost}</td>
                            <td>{data?.amountpaid}</td>
                            <td>{data?.outstanding}</td>
                            <td>{data?.projectduration}</td>
                            <td>
                              <Link
                                to={"/update-services/product/" + data?.id}
                                className="btn btn-primary"
                              >
                                Edit
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={"/view-services-product/" + data?.id}
                                className="btn btn-primary"
                              >
                                View
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={"/update-services-product/" + data?.id}
                                className="btn btn-primary"
                              >
                                Update
                              </Link>
                            </td>
                          </tr>
                        );
                      })}

                      <tr className="bg-dark text-white">
                        <th scope="row" colspan="3" style={{ width: "100%" }}>
                          Grand Total
                        </th>
                        {/* <td rowspan="2"></td> */}
                        <td rowspan="">
                          {naira}
                          {calculatePayable?.toLocaleString()}
                        </td>
                        <td rowspan="">
                          {naira}
                          {calculatePaid?.toLocaleString()}
                        </td>
                        <td colspan="4">
                          {naira}
                          {calculateOutstanding?.toLocaleString()}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </>
            )}
          </table>
          {/* <h2>
            Total Cost: {naira} {calculatePayable?.toLocaleString()}
          </h2>
          <hr />
          <h2>
            Amount Paid: {naira} {calculatePaid?.toLocaleString()}
          </h2>
          <hr />
          <h2>
            Outstanding: {naira} {calculateOutstanding?.toLocaleString()}
          </h2> */}
          <hr />
          <ExcelExport
            className="btn btn-info"
            table="emp-table"
            sheet="Sheet"
            buttonText="export to excel"
            filename="services-product"
          />
        </section>
      </main>
      {/* <!-- End #main --> */}
    </>
  );
}

export default ServicesProduct;
