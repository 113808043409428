import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import API_URL from "../../../config";
import { Spinner } from "react-bootstrap";
import "./forgot2.css";
import { useNavigate, useParams, Link } from "react-router-dom";

function ForgotPassword2() {
  const id = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [data, setData] = useState({
    email: "",
  });
  const url = `${API_URL}/auth/${id._id}/${id.token}`;

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(url, {
        email: data?.email,
      })
      .then(({ data }) => {
        setMessage(data?.payload[0]);
        setLoading(false);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const handle = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
    console.log(newData);
  };
  console.log(message);
  return (
    <>
      <section className="containar">
        <div className="section-main">
          {loading ? (
            <div className="text-center mt-5 pt-5">
              <Spinner
                animation="border"
                className="text-center"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <div className="container-center home-container">
              <center>
                <img src="../../../../images2/sparknews.png" width="30%" />
              </center>
              <h2>Don't Worry!</h2>
              <form method="post" onSubmit={(e) => handleSubmit(e)}>
                {err ? (
                  <div className="alert alert-danger text-center" role="alert">
                    <strong>{err}</strong>
                  </div>
                ) : (
                  <div style={{ display: "none" }}>{err}</div>
                )}
                {message ? (
                  <div
                    className="alert alert-success text-center "
                    role="alert"
                  >
                    <strong>{message}</strong>
                    {/* {alert("Login successful")} */}
                    {navigate("/success-password")}
                    {/* {message} */}
                  </div>
                ) : (
                  <div></div>
                )}
                <h4>provide us your email Address</h4>
                <formgroup>
                  <input
                    type="email"
                    name="email"
                    value={data?.email}
                    onChange={(e) => handle(e)}
                  />
                  <label for="email">
                    <br />
                    Email
                  </label>
                  <span>enter your email</span>
                </formgroup>

                <button id="login-btn" type="submit">
                  Next
                </button>
              <p className="text-dark">
                Did you remember? <Link to="/sparkdot-admin/auth">Sign In</Link>
              </p>
              </form>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default ForgotPassword2;
