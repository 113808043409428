import React from "react";
import "./auth.css";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { getToken, setAdminSession } from "../../../utils/Common";
import API_URL from "../../../config/index";
import styled from "styled-components"

function LoginRegister() {

  const navigate = useNavigate();
  const [visible, setVisible] = useState(false)
  const [passwordLength, setPasswordLength] = useState("");
  const [specialChars, setSpecialChars] = useState("");
  const [passwordNumber, setPasswordNumber] = useState("");
  const [uppercase, setUppercase] = useState("")
  const [login, setLogin] = useState(null);
  const [err, setErr] = useState(null);
  const [err2, setErr2] = useState(null);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState(null);
  const [message2, setMessage2] = useState(null);
  const [message3, setMessage3] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loginAdmin, setLoginAdmin] = useState(null);
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [data2, setData2] = useState({
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    password2: "",
    username: "",
  });

  const loginAdminUrl = `${API_URL}/auth/login`;
  const registerUrl = `${API_URL}/auth/register`;
  // handle login
  const handleLoginSubmit = (e) => {
    e.preventDefault();
    setErr(null);
    setLoading(true);
    axios
      .post(loginAdminUrl, {
        email: data.email,
        password: data.password,
      })
      .then(({ data }) => {
        console.log(data);
        setLoading(false);
        setMessage("Login successfull");
        setAdminSession(data?.message, data?.payload[0]);
        setTimeout(() => {
          setMessage3("Please wait while we redirect");
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setErr(err?.response?.data?.message);
        setShow(!show);
      });
  };

  const handleRegisterSubmit = (e) => {
    e.preventDefault();
    setErr(null);
    setLoading(true);
    axios
      .post(registerUrl, {
        email: data2?.email,
        password: data2?.password,
        username: data2?.username,
        password2: data2?.password2,
        firstname: data2?.firstname,
        lastname: data2?.lastname,
      })
      .then(({ data }) => {
        setMessage2(data?.message);
        setLoading(false);
        console.log(data);
      })
      .catch((err) => {
        setErr2(err?.response?.data?.message);
        // setShow(!show);
        setLoading(false);
      });
  };
  console.log(message);
  const handle = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
    console.log(newData);
  };
  const handle2 = (e) => {
    const newData = { ...data2 };
    newData[e.target.name] = e.target.value;
    setData2(newData);
    console.log(newData);

    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    if (format.test(newData.password)) {
      // return true;
      console.log("true")
      setSpecialChars("not-show")
    } else {
      // return false;
      setSpecialChars("")
    }

    if (newData.password.length > 6) {
      // return true;
      setPasswordLength("not-show")
    } else {
      // return false;
      setPasswordLength("")
    }

    function checkUppercase(str) {
      for (var i = 0; i < str.length; i++) {
        if (
          str.charAt(i) == str.charAt(i).toUpperCase() &&
          str.charAt(i).match(/[a-z]/i)
        ) {
          return true;
        }
      }
      return false;
    }


  
    checkUppercase(newData.password)
    if(checkUppercase(newData.password)){
      setUppercase("not-show")
    }else{
      setUppercase("")
    }

    // console.log(checkUppercase(newData))
    // if(checkUppercase() === false){
    //   setUppercase("")
    // }
  
    let str = newData.password;
    if (/\d/.test(str)) {
      setPasswordNumber("not-show")
    }else{
      setPasswordNumber("")
    }
  };


  
  const toggleForm = () => {
    const container = document.querySelector(".container");
    container.classList.toggle("active");
  };
  
  const mainToken = getToken();
  if (mainToken === true) {
    navigate("/");
  }
  const password_elem = document.getElementById("password");
  const main = document.querySelector(".forward");
  const changePassword = () => {
    main.classList.toggle("fa-eye-slash")
    if (password_elem.type === "password") {
      password_elem.type = "text";
    } else if (password_elem.type === "text") {
      password_elem.type = "password";
    }
  };
  
  return (
    <>
      <body>
        <section className="section-auth">
          <div className="container">
            <div className="user signinBx">
              <div className="imgBx">
                <img
                  src="https://images.pexels.com/photos/39584/censorship-limitations-freedom-of-expression-restricted-39584.jpeg?auto=compress&cs=tinysrgb&w=1000"
                  alt=""
                />
              </div>

              <div className="formBx">
                {loading ? (
                  <div className="text-center mt-5 pt-5">
                    <Spinner
                      animation="border"
                      className="text-center"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  <form onSubmit={handleLoginSubmit}>
                    {err ? (
                      <div class="alert alert-danger text-center" role="alert">
                        <strong>{err}</strong>
                      </div>
                    ) : (
                      <div style={{ display: "none" }}>{err}</div>
                    )}

                    {message ? (
                      <div
                        class="alert alert-success text-center "
                        role="alert"
                      >
                        <strong>{message}</strong>
                        <p style={{ display: "none" }}>
                          {setTimeout(() => (window.location.href = "/"), 3000)}
                        </p>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {message3 ? (
                      <>
                        <div className="text-center text-success mt-5 pt-5">
                          <Spinner
                            animation="border"
                            className="text-center"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </div>
                        <div className="text-center">
                          <p>{message3}</p>
                        </div>
                      </>
                    ) : (
                      <>
                        <h2>Sign In</h2>
                        <input
                          type="email"
                          name="email"
                          placeholder="email"
                          onChange={(e) => handle(e)}
                          value={data?.email}
                        />
                        <input
                          type="password"
                          name="password"
                          placeholder="Password"
                          onChange={(e) => handle(e)}
                          value={data?.password}
                        />
                        <input type="submit" name="" value="Login" />
                        <p className="signup">
                          Don't have an account ?
                          <a href="#" onClick={toggleForm}>
                            Sign Up.
                          </a>
                          <br />
                          <Link to="/reset-password">forgot password</Link>
                        </p>
                      </>
                    )}
                  </form>
                )}
              </div>
            </div>
            <div className="user signupBx">
              <div className="formBx">
                {loading ? (
                  <div className="text-center mt-5 pt-5">
                    <Spinner
                      animation="border"
                      className="text-center"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  <form action=""  id={`${visible && "main-form"}`} onSubmit={handleRegisterSubmit}>
                    {err2 ? (
                      <div class="alert alert-danger text-center" role="alert">
                        <strong>{err2}</strong>
                        {/* {setShow(show)} */}
                      </div>
                    ) : (
                      <div style={{ display: "none" }}>{err}</div>
                    )}
                    {message2 ? (
                      <div
                        class="alert alert-success text-center "
                        role="alert"
                      >
                        <strong>{message2}</strong>
                        {/* {setShow(show)} */}
                        {/* {alert("You have successfully registered")} */}
                        {navigate("/create-success")}
                        {/* {message} */}
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <h2>Create an account</h2>
                    <input
                      type="text"
                      name="username"
                      value={data2?.username}
                      placeholder="Username"
                      onChange={(e) => handle2(e)}
                      onClick={()=> setVisible(false)}
                    />
                    <input
                      type="text"
                      name="firstname"
                      value={data2?.firstname}
                      placeholder="firstname"
                      onChange={(e) => handle2(e)}
                      onClick={()=> setVisible(false)}
                    />
                    <input
                      type="text"
                      name="lastname"
                      value={data2?.lastname}
                      placeholder="lastname"
                      onChange={(e) => handle2(e)}
                      onClick={()=> setVisible(false)}
                    />
                    <input
                      type="email"
                      name="email"
                      value={data2?.email}
                      placeholder="Email Address"
                      onChange={(e) => handle2(e)}
                      onClick={()=> setVisible(false)}
                    />
                    <input
                      type="password"
                      name="password"
                      id="password"

                      placeholder="Create Password"
                      onChange={(e) => handle2(e)}
                      value={data2?.password}
                      onClick={()=> setVisible(!visible)}
                    />
                    <EyeStyling className="fa fa-eye forward" onClick={changePassword}></EyeStyling>
                    {/* <i class="fa fa-eye" aria-hidden="true"></i> */}

                    {visible && (
                      <div>
                        {/* uppercase section start */}

                        {uppercase === "" && (
                          <div>
                            <i class={`fa fa-ban text-danger`} aria-hidden="true"></i>&nbsp;<span style={{fontSize: "12px"}}>password must contain a upper case letter</span><br/>
                          </div>
                        )}
                        {uppercase === "not-show" && (
                          <div>
                            <i class="fa fa-check-square text-success" aria-hidden="true"></i>&nbsp;<span style={{fontSize: "12px"}}>password must contain a upper case letter</span><br/>
                          </div>
                        )}
                        {/* uppercase end */}

                        {/* special chars start */}
                        {specialChars === "" && (
                          <>
                            <i class={`fa fa-ban text-danger`} aria-hidden="true"></i>&nbsp;<span style={{fontSize: "12px"}}>password must contain a special chars</span><br/>
                          </>
                        )}
                        
                        {specialChars === "not-show" && (
                          <>
                            <i class="fa fa-check-square text-success" aria-hidden="true"></i>&nbsp;<span style={{fontSize: "12px"}}>password must contain a special chars</span><br/>
                          </>
                        )}
                        {/* special chars end */}
                        
                        {/* password length start */}
                        {passwordLength === "" && (
                          <>
                            <i class={`fa fa-ban text-danger`} aria-hidden="true"></i>&nbsp;<span style={{fontSize: "12px"}}>password must not be less than 6 characters</span><br/>
                          </>
                        )}
                        
                        {passwordLength === "not-show" && (
                          <>
                            <i class="fa fa-check-square text-success" aria-hidden="true"></i>&nbsp;<span style={{fontSize: "12px"}}>password must not be less than 6 characters</span><br/>
                          </>
                        )}
                        {/* password length end */}

                        {/* password number start */}
                        {passwordNumber === "" && (
                          <>
                            <i class={`fa fa-ban text-danger`} aria-hidden="true"></i>&nbsp;<span style={{fontSize: "12px"}}>password must contain a number</span><br/>
                          </>
                        )}
                        
                        {passwordNumber === "not-show" && (
                          <>
                            <i class="fa fa-check-square text-success" aria-hidden="true"></i>&nbsp;<span style={{fontSize: "12px"}}>password must contain a number</span><br/>
                          </>
                        )}
                        {/* password number end */}
                      </div>
                    )}
                    
                    <input
                      type="password"
                      name="password2"
                      placeholder="Confirm Password"
                      value={data2?.password2}
                      onChange={(e) => handle2(e)}
                    />
                    {uppercase === "not-show" && specialChars === "not-show" && passwordLength === "not-show" && passwordNumber  === "not-show"  ? (
                      <input type="submit" value="Sign Up" />
                      ) : (
                        <input type="submit" value="Sign Up" style={{backgroundColor: "gray", opacity: "0.7"}} disabled={true} />
                    )}
                    <p className="signup">
                      Already have an account ?
                      <a href="#" onClick={toggleForm}>
                        Sign in.
                      </a>
                    </p>
                  </form>
                )}
              </div>
              <div className="imgBx">
                <img
                  src="https://images.pexels.com/photos/3127880/pexels-photo-3127880.jpeg?auto=compress&cs=tinysrgb&w=1000"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
      </body>
    </>
  );
}

const EyeStyling = styled.i`
    position: relative;
    right: -91%;
    z-index: 1;
    top: -41px;
`;

const EyeStyling2 = styled.i`
    right: 18%;
    z-index: 1;
    top: 57%;
    position: absolute;
`;
export default LoginRegister;
