import React, { useState, useEffect } from "react";
import { handleMoving } from "../handles";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Spinner } from "react-bootstrap";
import API_URL from "../../../config";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import ExcelExport from "react-html-table-to-excel";
// import "../../../App.css"
// import "./style.css"
function RegisteredSchool() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [category, setCategory] = useState(null);
  const [message, setMessage] = useState(null);
  const [err, setErr] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const url = `${API_URL}/product/register/product/${id}`;
  useEffect(() => {
    setLoading(true);
    axios.get(url).then(({ data }) => {
      setCategory(data?.payload[0]);
      console.log(data)
      setLoading(false);
    });
  }, [url]);

      // search
      const [searchField, setSearchField] = useState("");
      const [searchShow, setSearchShow] = useState(false);
    
   
      const filteredPersons = category?.filter((person) => {
        return (
          person?.nameofschool?.toLowerCase()?.includes(searchField?.toLowerCase()) ||
          person?.visited?.address?.toLowerCase()?.includes(searchField?.toLowerCase()) ||
          person?.package?.toLowerCase()?.includes(searchField?.toLowerCase())
        );
      });
  
      console.log(filteredPersons)
  
  
  
    const handleChange = (e) => {
      setSearchField(e.target.value);
      if (e.target.value === "") {
        setSearchShow(false);
      } else {
        setSearchShow(true);
      }
    };

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Registered School Josrac</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Home</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">Regisitered Schools</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}
        <button className="btn btn-primary" onClick={() => navigate(-1)}>
          Go back
        </button>

        <section className="section profile p-5 table-responsive">
          <div className="pb-4">
            <Link
              to={"/all-visited/" + id}
              className="btn btn-primary"
            >
              create Registered School
            </Link><br /><br />
            <input
            class="form-control me-2"
            type="search"
            placeholder="Enter school name, package or school address"
            aria-label="Search"
            onChange={handleChange}
            style={{
              display: "block",
              width: "100%",
              height: "38px",
              fontSize: "16px",
            }}
          />
          </div>
          <table className="table table-responsive" id="emp-table">
            <thead>
              <tr>
                <th scope="col">S/N</th>
                <th scope="col">Name of schools</th>
                <th scope="col">Location Address</th>
                <th scope="col">Date</th>
                <th scope="col">Phone</th>
                <th scope="col">Session</th>
                <th scope="col">Package</th>
                <th scope="col">Remark</th>
              </tr>
            </thead>

            {/* {category?.map((data) => {})} */}
            <tbody>
              {loading ? (
                <div className="text-center mt-5 pt-5">
                  <Spinner
                    animation="border"
                    className="text-center"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <>
                  {err ? (
                    <>
                      <h5 className="text-center p-4">{err}</h5>
                    </>
                  ) : (
                    <>
                    {searchShow ? (
                      <>
                      {filteredPersons?.length === 0 ? (
                        <>
                          No school found with the above search <strong>{searchField}</strong>
                        </>
                      ) : (
                        <>
                        {filteredPersons?.map((data) => {
                        return (
                          <tr>
                            <th scope="row">*</th>
                            <td>{data?.nameofschool}</td>
                            <td>{data?.visited?.address}</td>
                            <td>{data?.date}</td>
                            <td>{data?.number}</td>
                            <td>{data?.session}</td>
                            <td>{data?.package}</td>
                            <td>{data?.remark}</td>
                            <td>
                              <Link
                                to={"/update-registered-schoolJosrac/" + data?.id}
                                className="btn btn-primary"
                              >
                                Edit
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={"/view-registered-school/" + data?.id}
                                className="btn btn-primary"
                              >
                                View
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={"/update-registered-school/" + data?.id}
                                className="btn btn-primary"
                              >
                                Update
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                        </>
                      )}
                      </>
                    ) : (
                      <>
                      
                      {category?.map((data) => {
                        return (
                          <tr>
                            <th scope="row">*</th>
                            <td>{data?.nameofschool}</td>
                            <td>{data?.visited?.address}</td>
                            <td>{data?.date}</td>
                            <td>{data?.number}</td>
                            <td>{data?.session}</td>
                            <td>{data?.package}</td>
                            <td>{data?.remark}</td>
                            <td>
                              <Link
                                to={"/update-registered-schoolJosrac/" + data?.id}
                                className="btn btn-primary"
                              >
                                Edit
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={"/view-registered-school/" + data?.id}
                                className="btn btn-primary"
                              >
                                View
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={"/update-registered-school/" + data?.id}
                                className="btn btn-primary"
                              >
                                Update
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                      </>
                    )}
                    {/* {searchShow ?  (
                      <>
                      {filteredPersons?.length === 0 ? (
                        <>
                          No school found with the above search <strong>{searchField}</strong>
                        </>
                      ) : (
                        <>
                        </>
                      ))} */}


                    </>
                  )}
                </>
              )}
            </tbody>
          </table>
          <ExcelExport
            className="btn btn-info"
            table="emp-table"
            sheet="Sheet"
            buttonText="export to excel"
            filename="Registered School"
          />
        </section>
      </main>
      {/* <!-- End #main --> */}
    </>
  );
}

export default RegisteredSchool;
