import React, { useState, useEffect } from "react";
import { handleMoving } from "../handles";
import { Spinner, Button } from "react-bootstrap";
import axios from "axios";
import API_URL from "../../../config";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
// import ReactExport from "react-export-excel";
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import ExcelExport from "react-html-table-to-excel";
import ModalDelete from "../../ModalDelete";

// import "../../../App.css"
// import "./style.css"
function SingleJosracSuscribed() {
  const navigate = useNavigate();
  const [ids, setId] = useState(null);
  const [school, setSchool] = useState(null)
  const { id } = useParams();
  const [single, setSingle] = useState(null);
  const [single2, setSingle2] = useState(null);
  const [message, setMessage] = useState(null);
  const [err, setErr] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const url = `${API_URL}/product/suscribed/${id}`;
  useEffect(() => {
    setLoading(true);
    axios.get(url).then(({ data }) => {
      setSingle(data?.payload[0]);
      setLoading(false);
      // console.log(data)
    });
  }, [url]);
  const url2 = `${API_URL}/product/suscribed-updated/all/${id}`;
  useEffect(() => {
    setLoading(true);
    axios.get(url2).then(({ data }) => {
      setSingle2(data?.payload[0]);
      setLoading(false);
      // console.log(data)

      console.log(data)
    });
  }, [url2]);

  const handleId = (ids,schoolname, e) => {
    e.preventDefault();
    setId(ids);
    setSchool(schoolname)
  };

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Single Suscibed School</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">Suscribed</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile p-5 table-responsive">
        <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Go back
          </button>

          {loading ? (
            <>
              <div className="text-center mt-5 pt-5">
                <Spinner
                  animation="border"
                  className="text-center"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            </>
          ) : (
            <>
            {message ? (
              <div class="alert alert-success text-center " role="alert">
                <strong>{message}</strong>
                <p style={{ display: "none" }}>
                  {setTimeout(() => navigate("/suscribed-schools/" + single?.product?._id), 3000)}
                  {/* {window.location.reload()} */}
                </p>
              </div>
            ) : (
              <div></div>
            )}
          <h2>Product: {single?.product?.name}</h2>
          <hr />
          <h2>Name-of-school: {single?.nameofschool}</h2>
          <hr />
          <h2>Payment Description: {single?.paymentdescription}</h2>
          <hr />
          <h2>Application Fee: {single?.activationfee?.toLocaleString()}</h2>
          <hr />
          <h2>
            Subsrciption Cost: {single?.subscriptioncost?.toLocaleString()}
          </h2>
          <hr />
          <h2>Total Payable: {single?.totalpayable?.toLocaleString()}</h2>
          <hr />
          <h2>Amount Paid: {single?.amountpaid?.toLocaleString()}</h2>
          <hr />
          <h2>Outstanding: {single?.outstanding?.toLocaleString()}</h2>
          <hr />
          <h2>School Status: {single?.schoolstatus}</h2>
          <hr />
          <h2>Session: {single?.session}</h2>
          <hr />
          <h2>Package: {single?.package}</h2>
          <hr />
          <h2>Mode of Payment: {single?.modeofpayment}</h2>
          <hr />
          <h2>Remark: {single?.remark}</h2>
          <hr />
          <h2>Payment Date: {single?.paymentdate}</h2>
          <hr />
          <h2>
            Last Updated:{" "}
            {single?.updatedAt?.replace(/T/, " ")?.replace(/\..+/, "")}
          </h2>
          <hr />
          <Link
            to={"/update-suscribed-school/" + single?.id}
            className="btn btn-primary"
          >
            Edit
          </Link><br />
          <Button  
            onClick={(e) => handleId(single?.id,single?.nameofschool, e)} 
            variant="dark"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            >
            Delete
          </Button>
          <br /> <br />
          <hr />
          {/* {single2?.map((data) => {
                return (
                  <></>
                )} */}
          {single2?.map((data) => {
            return (
              <>
                <hr />
                <h2>Name-of-school: {data?.nameofschool}</h2>
                <hr />
                <h2>Payment Description: {data?.paymentdescription}</h2>
                <hr />
                <h2>
                  Application Fee: {data?.activationfee?.toLocaleString()}
                </h2>
                <hr />
                <h2>
                  Subsrciption Cost: {data?.subscriptioncost?.toLocaleString()}
                </h2>
                <hr />
                <h2>Total Payable: {data?.totalpayable?.toLocaleString()}</h2>
                <hr />
                <h2>Amount Paid: {data?.amountpaid?.toLocaleString()}</h2>
                <hr />
                <h2>Outstanding: {data?.outstanding?.toLocaleString()}</h2>
                <hr />
                <h2>School Status: {data?.schoolstatus}</h2>
                <hr />
                <h2>Session: {data?.session}</h2>
                <hr />
                <h2>Package: {data?.package}</h2>
                <hr />
                <h2>Mode of Payment: {data?.modeofpayment}</h2>
                <hr />
                <h2>Remark: {data?.remark}</h2>
                <hr />
                <h2>Payment Date: {data?.paymentdate}</h2>
                <hr />
                <h2>
                  Last Updated:{" "}
                  {data?.updatedAt?.replace(/T/, " ")?.replace(/\..+/, "")}
                </h2>
                <br /> <br />
                <hr />
              </>
            );
          })}

            </>
          )}

            <ModalDelete
              title="Delete Suscribed school"
              writeup={`Are you sure you want to permanently delete suscribed school "${school}" school and it's content?`}
              id={ids}
              url={`${API_URL}/product/suscribed/delete/`}
              setMessage={setMessage}
              setLoading={setLoading}
              setErr={setErr}
            />
        </section>
      </main>
      {/* <!-- End #main --> */}
    </>
  );
}

export default SingleJosracSuscribed;
