import React, { useState, useEffect } from "react";
// import { handleMoving } from "../handles";
import Header from "../../../Header";
import Sidebar from "../../../Sidebar";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import API_URL from "../../../../../config/index";
import { Button, Form, Spinner } from "react-bootstrap";
import { getToken } from "../../../../../utils/Common";
import axios from "axios";

// import "../../../App.css"
// import "./style.css"
function CreateRegisteredSchool() {
  const location = useLocation()
  const [select, setSelect] = useState(null)
  const navigate = useNavigate();
  const { id } = useParams();
  const [message, setMessage] = useState(null);
  const [show, setShow] = useState(false);
  const [err, setErr] = useState(null);
  const [suscribedstatus, setSuscribedStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    nameofschool: "",
    address: "",
    number: "",
    date: "",
    remark: "",
    session: "",
  });

  const url = `${API_URL}/product/register/create/${id}`;

  // const token = getToken();
  // const axiosCreate = axios.create({
  //   headers: {
  //     authorization: "Bearer " + token,
  //   },
  // });

  const handleSubmit = (e) => {
    // const formData = new FormData();
    // console.log(image)
    // formData.append("title", data?.title);
    e.preventDefault();
    setShow(false);
    setLoading(true);
    axios
      .post(url, {
        nameofschool: location?.state?.nameofschool,
        address: data?.address,
        number: data?.number,
        date: data?.date,
        remark: data?.remark,
        session: data?.session,
        package: select,
        visited: location?.state?.id
      })
      .then(({ data }) => {
        setMessage(data?.message);
        setLoading(false);
      })
      .catch((err) => {
        setShow(true)
        setErr(err?.response?.data?.message);
        console.log(err?.response);
        // console.log(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const handle = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
    console.log(newData);
  };

  const handleCategory = (e) => {
    e.preventDefault();
    setSuscribedStatus(e.target.value);
  };
  console.log(suscribedstatus);

  const handleSelect = (e) => {
    setSelect(e.target.value);
  };

  // console.log(location?.state?.id)


  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Create Registered School</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">
                Create Registered School
              </li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profil table-responsive">
        <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Go back
          </button>
          {loading ? (
            <div className="text-center mt-5 pt-5">
              <Spinner animation="border" className="text-center" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              {err && show ? (
                <div
                  class="alert alert-danger text-center container w-50"
                  role="alert"
                >
                  <strong>{err}</strong>
                </div>
              ) : (
                <div style={{ display: "none" }}>{err}</div>
              )}

              {message ? (
                <div class="alert alert-success text-center " role="alert">
                  <strong>{message}</strong>
                  <p style={{ display: "none" }}>
                  {setTimeout(() => navigate(`/registered-schools/${id}`), 3000)}
                  </p>
                </div>
              ) : (
                <div></div>
              )}
              <h2 className="display-5 text-center m-5">
                Create Registered School Josrac
              </h2>
              <Form
                className="w-50 mx-auto"
                onSubmit={(e) => handleSubmit(e)}
                //   enctype="multipart/form-data"
                method="post"
                style={{ width: "50%", margin: "auto" }}
              >
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name of School</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="enter name of school"
                    className="w-100"
                    value={location?.state?.nameofschool}
                    name="nameofschool"
                    disabled
                    // required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Tel No</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="enter Tel No"
                    className="w-100"
                    value={data?.number}
                    onChange={(e) => handle(e)}
                    name="number"
                    // required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="enter Date"
                    className="w-100"
                    value={data?.date}
                    onChange={(e) => handle(e)}
                    name="date"
                    // required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="enter Address"
                    className="w-100"
                    value={data?.address}
                    onChange={(e) => handle(e)}
                    name="address"
                    // required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Session</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="enter Session"
                    className="w-100"
                    value={data?.session}
                    onChange={(e) => handle(e)}
                    name="session"
                    // required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Remark</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="enter remark"
                    className="w-100"
                    value={data?.remark}
                    onChange={(e) => handle(e)}
                    name="remark"
                    // required
                  />
                </Form.Group>
                <label for="Package">Package</label>
                <select
                    name="package"
                    className="form-control"
                    value={select}
                    onChange={(e) => handleSelect(e)}
                  >
                    <option selected>
                      Select Package
                    </option>
                    <option value="Jpro">
                      Jpro
                    </option>
                    <option value="Jpremium">
                      Jpremium
                    </option>
                    <option value="Jbasic">
                      Jbasic
                    </option>
                    <option value="Other">
                      Other
                    </option>
                  </select><br />
                <Button variant="primary" type="submit">
                  Create Registered School
                </Button>
                {/* <Button variant="outline-warning">Go Back</Button> */}
              </Form>
            </>
          )}
        </section>
      </main>
      {/* <!-- End #main --> */}
    </>
  );
}

export default CreateRegisteredSchool;
