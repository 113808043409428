import React from "react";
import "./forgot.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router";
import API_URL from "../../../config";
import { useNavigate, Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";

function ForgotPassword() {
  const id = useParams();
  const navigate = useNavigate();
  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    password: "",
  });
  const [message, setMessage] = useState(null);

  const url = `${API_URL}/auth/reset/${id.admin}/${id.token}`;

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(url, {
        password: data.password,
      })
      .then(({ data }) => {
        setMessage(data?.message);
        // console.log(data);
        setLoading(false);

      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        console.log(err?.response?.data?.message);
        setLoading(false);
        // setShow(!show);
      });
  };

  const cancel = ()=>{
    navigate("/")
  }
  const handle = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
    console.log(newData);
  };
  return (
    <>
      <div className="containar">
        <div className="shape"></div>
        <div className="shape"></div>
        <div className="shape"></div>
        <div className="shape"></div>
        <div className="shape"></div>
        <div className="shape"></div>
        <div className="shape"></div>
        <div className="shape"></div>
        <div className="shape"></div>
        <div className="shape"></div>
        <div className="shape"></div>
        <div className="shape"></div>
        <div className="shape"></div>
        <div className="shape"></div>
        <div className="shape"></div>
        <div className="shape"></div>
        <div className="shape"></div>
        <div className="shape"></div>
        <div className="shape"></div>
        <div className="shape"></div>
        <div className="wrapper">
          {loading ? (
            <div className="text-center mt-5 pt-5">
              <Spinner animation="border" className="text-center" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              <form action="" method="post" onSubmit={(e) => handleSubmit(e)}>
                {err ? (
                  <div className="alert alert-danger text-center" role="alert">
                    <strong>{err}</strong>
                  </div>
                ) : (
                  <div style={{ display: "none" }}>{err}</div>
                )}

                {message ? (
                  <div
                    className="alert alert-success text-center "
                    role="alert"
                  >
                    <strong>{message}</strong>
                    {/* {alert("Login successful")} */}
                    {/* {navigate("/sparkdot-admin/auth")} */}
                    {/* {message} */}
                    <button>
                      <Link to="/auth">Login</Link>
                    </button>
                  </div>
                ) : (
                  <div></div>
                )}
                <h1>two step verification</h1>
                <img
                  src="https://images.unsplash.com/photo-1548021029-d6cf339a5ce4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80"
                  alt=""
                />
                <p>Enter your new password</p>
                <div className="code">
                  <input
                    type="text"
                    name="password"
                    onChange={(e) => handle(e)}
                    value={data?.password}
                  />
                  {/* <input type="number" name="code" maxLength="1" /> */}
                  {/* <input type="number" name="code" maxLength="1" /> */}
                  {/* <input type="number" name="code" maxLength="1" /> */}
                </div>
                <button>Submit</button>
                <button className="cancel" onClick={cancel}>Cancel</button>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
