import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../../Header";
import Sidebar from "../../../Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import API_URL from "../../../../../config/index";
import { Button, Form, Spinner } from "react-bootstrap";
import { getToken } from "../../../../../utils/Common";
function UpdateProduct() {
  const id = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [image, setImage] = useState(null);
  const [select, setSelect] = useState("");

  // handling state for the input field text
  const [data, setData] = useState({
    name: "",
    category: "",
    subcategory: "",
    description: "",
  });
  const url = `${API_URL}/product/update/${id}`;

  const token = getToken();
  const axiosCreate = axios.create({
    headers: {
      authorization: "Bearer " + token,
    },
  });


  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    setShow(false);
    const formData = new FormData();
    
    formData.append("name", data?.name);
    formData.append("description", data?.description);
    formData.append("category", select);
    formData.append("subcategory", data?.subcategory);
    formData.append("image", image);
    axios
      .post(url, formData)
      .then(({ data }) => {
        setMessage(data?.message);
        setLoading(false)
      })
      .catch((err) => {
        setShow(true);
        setErr(err?.response?.data?.message);
        setLoading(false)
      });
  };

  const url2 = API_URL + "/product/" + id.id;
  useEffect(() => {
    // setLoading(true);
    axios.get(url2).then(({ data }) => {
      setData(data?.payload[0]);
      setImage(data?.payload[0]?.image);
      setSelect(data?.payload[0]?.category);
      // console.log(data)
      setLoading(false);
    });
  }, [url2]);

  const handle = (e) => {
    let newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  };

  const handleImage = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSelect = (e) => {
    setSelect(e.target.value);
  };
  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Update Product</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">Update Product</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile">
        <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Go back
          </button>
          {loading ? (
            <div className="text-center mt-5 pt-5">
              <Spinner animation="border" className="text-center" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              {err && show ? (
                <div
                  class="alert alert-danger text-center container w-50"
                  role="alert"
                >
                  <strong>{err}</strong>
                </div>
              ) : (
                <div style={{ display: "none" }}>{err}</div>
              )}

              {message ? (
                <div class="alert alert-success text-center " role="alert">
                  <strong>{message}</strong>
                  <p style={{ display: "none" }}>
                    {setTimeout(() => navigate("/products"), 3000)}
                  </p>
                </div>
              ) : (
                <div></div>
              )}
              <h2 className="display-5 text-center m-5">Update Product</h2>
              <Form
                className=""
                onSubmit={(e) => handleSubmit(e)}
                enctype="multipart/form-data"
                method="post"
                style={{ width: "", margin: "auto" }}
              >
                <h3>Update Product</h3>

                <div className="row">
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-6 col-sm-6">
                        <Form.Label className="label">
                          Name of Product
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="enter name of product"
                          className="form-control"
                          value={data?.name}
                          onChange={(e) => handle(e)}
                          name="name"
                          required
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <Form.Label className="label">
                          Product Category
                        </Form.Label>
                        <select
                          name="category"
                          className="form-control"
                          value={select}
                          onChange={(e) => handleSelect(e)}
                        >
                          <option selected>
                            Select one of the following options
                          </option>
                          <option value="3D Modeling Software">
                            3D Modeling Software
                          </option>
                          <option selected>Select product category</option>
                          <option value="3D Printing Software">
                            3D Printing Software
                          </option>
                          <option value="API Design Software">
                            API Design Software
                          </option>
                          <option value="API Management Tools">
                            API Management Tools
                          </option>
                          <option value="API Security Tools">
                            API Security Tools
                          </option>
                          <option value="Access Gateways">
                            Access Gateways
                          </option>
                          <option value="Antivirus Software">
                            Antivirus Software
                          </option>
                          <option value="App Development Platform">
                            App Development Platform
                          </option>
                          <option value="Application Infrastructure Software">
                            Application Infrastructure Software
                          </option>
                          <option value="Application Performance Management (APM) Software">
                            Application Performance Management (APM) Software
                          </option>
                          <option value="Application Server Software">
                            Application Server Software
                          </option>
                          <option value="Archiving Software">
                            Archiving Software
                          </option>
                          <option value="Asset Tokenization Platform">
                            Asset Tokenization Platform
                          </option>
                          <option value="Authentication Systems">
                            Authentication Systems
                          </option>
                          <option value="Automobile Software">
                            Automobile Software
                          </option>
                          <option value="Blockchain Analysis Software">
                            Blockchain Analysis Software
                          </option>
                          <option value="Blockchain-as-a-Service (BaaS) Solutions">
                            Blockchain-as-a-Service (BaaS) Solutions
                          </option>
                          <option value="Bot Mitigation Software">
                            Bot Mitigation Software
                          </option>
                          <option value="Business Internet Service Providers (ISPs)">
                            Business Internet Service Providers (ISPs)
                          </option>
                          <option value="Business Process Automation Software">
                            Business Process Automation Software
                          </option>
                          <option value="Business Rules Management Systems (BRMS)">
                            Business Rules Management Systems (BRMS)
                          </option>
                          <option value="Custom Software Development Services">
                            Custom Software Development Services
                          </option>
                          <option value="Data Mapping Software">
                            Data Mapping Software
                          </option>
                          <option value="Data Preparation Tools">
                            Data Preparation Tools
                          </option>
                          <option value="Data Privacy Management Software">
                            Data Privacy Management Software
                          </option>
                          <option value="Disaster Recovery Software">
                            Disaster Recovery Software
                          </option>
                          <option value="Educational Software">
                            Educational Software
                          </option>
                          <option value="Finance Software (Fintech)">
                            Finance Software (Fintech)
                          </option>
                          <option value="Financial Risk Management Software">
                            Financial Risk Management Software
                          </option>
                          <option value="Incident Response Platforms">
                            Incident Response Platforms
                          </option>
                          <option value="Industrial IoT Solutions">
                            Industrial IoT Solutions
                          </option>
                          <option value="Office Suites Software">
                            Office Suites Software
                          </option>
                          <option value="Remote Support Software">
                            Remote Support Software
                          </option>
                        </select>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <Form.Label className="label">Sub-Category</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) => handle(e)}
                          name="subcategory"
                          className="form-control"
                          value={data?.subcategory}
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <Form.Label className="label">
                          Product Logo Icon
                        </Form.Label>
                        <Form.Control
                          type="file"
                          className="form-control"
                          onChange={(e) => handleImage(e)}
                          name="image"
                          required
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Label className="label">
                          Product Description
                        </Form.Label>
                        <textarea
                          className="form-control"
                          placeholder="Enter Product Description..."
                          style={{ height: 150 }}
                          value={data?.description}
                          name="description"
                          onChange={(e)=> handle(e)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                > */}

                {/* </Form.Group> */}
                <Button variant="primary" type="submit">
                  Update Product
                </Button>
                {/* <Button variant="outline-warning">Go Back</Button> */}
              </Form>
            </>
          )}
        </section>
      </main>
      {/* <!-- End #main --> */}
    </>
  );
}

export default UpdateProduct;
