import React, { useState, useEffect } from "react";
import { Table, Button, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import axios from "axios";
import API_URL from "../../config/index";
import ExcelExport from "react-html-table-to-excel";
import ModalDelete from "../ModalDelete";
function AllProduct() {
  const navigate = useNavigate();
  const [category, setCategory] = useState(null);
  const [message, setMessage] = useState(null);
  const [productid, setId] = useState(null);
  const [err, setErr] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  // const url = `${API_URL}/product`;
  const url = API_URL + "/product";
  useEffect(() => {
    setLoading(true);
    axios.get(url).then(({ data }) => {
      setCategory(data?.payload);
      // console.log(data)
      setLoading(false);
    });
  }, [url]);

  const handleId = (id, e) => {
    e.preventDefault();
    setId(id);
  };


  console.log(category)
  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Home Page</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}
        <button className="btn btn-primary" onClick={() => navigate(-1)}>
          Go back
        </button>

        <h2 className="display-5 text-center m-5">All Products</h2>
        {loading ? (
          <div className="mt-5 pt-5">
            <Spinner
              animation="border"
              className="text-center offset-md-6"
              role="status"
            >
              <span className="visually-hidden">loading.....</span>
            </Spinner>
          </div>
        ) : (
          <>
            {message ? (
              <div class="alert alert-success text-center " role="alert">
                <strong>{message}</strong>
                <p style={{ display: "none" }}>
                  {setTimeout(() => navigate("/products"), 3000)}
                  {window.location.reload()}
                </p>
              </div>
            ) : (
              <div></div>
            )}
            <Table striped bordered hover size="sm" id="emp-table">
              <thead>
                <tr>
                  <th>S/n</th>
                  <th>Product</th>
                  {/* <th>status</txh> */}
                  <th>operation</th>
                  <th>operation</th>
                </tr>
              </thead>
              {category?.map((data) => {
                return (
                  <>
                    <tbody>
                      <tr>
                        <td>*</td>
                        <td>
                          <strong className="text-uppercase">
                            {data?.name}
                          </strong>
                        </td>
                        <td>
                          <Button variant="primary">
                            <Link
                              to={`/product/update/${data?.id}`}
                              className="text-white"
                            >
                              Edit
                            </Link>
                          </Button>
                        </td>
                        <td>
                          <Button variant="primary">
                            <Link
                              to={`/product/view/${data?.id}`}
                              className="text-white"
                            >
                              View
                            </Link>
                          </Button>
                        </td>
                        <td>
                          <Button
                            variant="danger"
                            // onClick={(e) => handleDelete(data?.id, e)}
                            onClick={(e) => handleId(data?.id, e)}
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </>
                );
              })}
            </Table>
            <ModalDelete
              title="Delete Product"
              writeup="Are you sure you want to delete this product and it's content?"
              id={productid}
              url={`${API_URL}/product/delete/`}
              setMessage={setMessage}
              setLoading={setLoading}
              setErr={setErr}
            />
            <ExcelExport
              className="btn btn-info"
              table="emp-table"
              sheet="Sheet"
              buttonText="export to excel"
              filename="All Product"
            />
          </>
        )}
      </main>
    </>
  );
}

export default AllProduct;
