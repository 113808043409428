import axios from "axios";
import React, { useEffect, useState } from "react";
import "./success.css";

function CreateSuccessPaasword() {
  return (
    <>
      <body className="home">
        <div class="container-mains">
          <div class="circle-border"></div>
          <div class="circle">
            <div class="success"></div>
            <div class="verify-completed">
              <h3 className="">
                Password reset link has been sent your email
              </h3>
            </div>
          </div>
        </div>
      </body>
    </>
  );
}

export default CreateSuccessPaasword;
