import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { handleMoving } from "../handles";
import Header from "../Header";
import Sidebar from "../Sidebar";
import API_URL from "../../../config";
// import "../../../App.css"
import { Button, Form, Spinner } from "react-bootstrap";

import { getUserToken, getToken } from "../../../utils/Common";
import axios from "axios";
// import "./style.css"
function UpdateCreateGrossSalary() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [message, setMessage] = useState(null);
  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState({
    nameofemployee: "",
    serialnumber: "",
    grosssalary: "",
    basicsalary: "",
    rechargecard: "",
    bonus: "",
    allowance: "",
  });

  const url = `${API_URL}/payroll/gross/create-update/${id}`;

  const token = getToken();
  const axiosCreate = axios.create({
    headers: {
      authorization: "Bearer " + token,
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setShow(false);
    axios
      .post(url, {
        nameofemployee: data?.nameofemployee,
        serialnumber: data?.serialnumber,
        grosssalary: data?.grosssalary,
        basicsalary: data?.basicsalary,
        rechargecard: data?.rechargecard,
        bonus: data?.bonus,
        allowance: data?.allowance,
      })
      .then(({ data }) => {
        setMessage(data?.message);
        setLoading(false);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setLoading(false);
        setShow(true);
      });
  };

  const handle = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
    console.log(newData);
  };
  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <button className="btn btn-primary" onClick={() => navigate(-1)}>
          Go Back
        </button>
        <div className="pagetitle">
          <h1>Gross</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">Update</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}
        <section className="section profil table-responsive">
        <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Go back
          </button>
          {loading ? (
            <div className="text-center mt-5 pt-5">
              <Spinner animation="border" className="text-center" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              {err && show ? (
                <div
                  class="alert alert-danger text-center container w-50"
                  role="alert"
                >
                  <strong>{err}</strong>
                </div>
              ) : (
                <div style={{ display: "none" }}>{err}</div>
              )}

              {message ? (
                <div class="alert alert-success text-center " role="alert">
                  <strong>{message}</strong>
                  <p style={{ display: "none" }}>
                    {setTimeout(() => navigate("/gross"), 3000)}
                  </p>
                </div>
              ) : (
                <div></div>
              )}
              <h2 className="display-5 text-center m-5">Update Gross Salary</h2>
              <Form
                className="w-50 mx-auto"
                onSubmit={(e) => handleSubmit(e)}
                //   enctype="multipart/form-data"
                method="post"
                style={{ width: "50%", margin: "auto" }}
              >
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name of Employee</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="enter nameofemployee"
                    className="w-100"
                    value={data?.nameofemployee}
                    onChange={(e) => handle(e)}
                    name="nameofemployee"
                    // required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Serial Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="enter serialnumber"
                    className="w-100"
                    value={data?.serialnumber}
                    onChange={(e) => handle(e)}
                    name="serialnumber"
                    // required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Gross Salary</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="enter gross Salary"
                    className="w-100"
                    value={data?.grosssalary}
                    onChange={(e) => handle(e)}
                    name="grosssalary"
                    // required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Basic Salary</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="enter basic salary"
                    className="w-100"
                    value={data?.basicsalary}
                    onChange={(e) => handle(e)}
                    name="basicsalary"
                    // required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Recharge Card</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="enter recharge card"
                    className="w-100"
                    value={data?.rechargecard}
                    onChange={(e) => handle(e)}
                    name="rechargecard"
                    // required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Bonus</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="enter bonus"
                    className="w-100"
                    value={data?.bonus}
                    onChange={(e) => handle(e)}
                    name="bonus"
                    // required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Allowance</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="enter allowance"
                    className="w-100"
                    value={data?.allowance}
                    onChange={(e) => handle(e)}
                    name="allowance"
                    // required
                  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Update Gross Salary
                </Button>
                {/* <Button variant="outline-warning">Go Back</Button> */}
              </Form>
            </>
          )}
        </section>
      </main>
      {/* <!-- End #main --> */}
    </>
  );
}

export default UpdateCreateGrossSalary;

// <section className="section profile p-5 table-responsive">
// <table class="table table-responsive">
//   <thead>
//     <tr>
//       <th scope="col">S/N</th>
//       <th scope="col">Service Type</th>
//       <th scope="col">Description No</th>
//       <th scope="col">Total Cost</th>
//       <th scope="col">Amount Paid</th>
//       <th scope="col">Outstanding</th>
//       <th scope="col">Project Duration</th>
//       <th scope="col">Status</th>
//     </tr>
//   </thead>
//   <tbody>
//     <tr>
//       <th scope="row">*</th>
//       <td>Mark</td>
//       <td>Otto</td>
//       <td>@mdo</td>
//       <td>@mdo</td>
//       <td>@mdo</td>
//       <td>@mdo</td>
//       <td>@mdo</td>
//     </tr>
//     {/* {category?.suscribed?.length === 0 ? (
//       <>
//         <h5 className="text-center p-4">No Visited School yet</h5>
//       </>
//     ) : (
//       <>
//         {category?.suscribed?.map((data) => {
//           return (
//             <tr>
//               <th scope="row">*</th>
//               <td>{data?.nameofemployee}</td>
//               <td>{data?.schoolstatus}</td>
//               <td>{data?.nameofemployee}</td>
//               <td>{data?.grosssalary}</td>
//               <td>{data?.basicsalary}</td>
//               <td>{data?.rechargecard}</td>
//               <td>{data?.projectduration}</td>
//               <td>{data?.status}</td>
//               <td>
//                 <Link
//                   to={"/update-suscribed-school/" + data?.id}
//                   className="btn btn-primary"
//                 >
//                   Edit
//                 </Link>
//               </td>
//             </tr>
//           );
//         })}
//       </>
//     )} */}
//   </tbody>
// </table>
// </section>
