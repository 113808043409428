export const getAdmin = () => {
  const userStr = localStorage.getItem("josracadmin");
  if (userStr) {
    return JSON.parse(userStr);
  } else {
    return null;
  }
};

export const getToken = () => {
  return localStorage.getItem("jos") || null;
};

export const setUserSession = (token, user) => {
  localStorage.setItem("token", token);
  localStorage.setItem("user", JSON.stringify(user));
};

export const getUserToken = () => {
  return localStorage.getItem("token") || null, localStorage.getItem("user");
};

export const setAdminSession = (token, admin) => {
  localStorage.setItem("jos", token);
  localStorage.setItem("josracadmin", JSON.stringify(admin));
};

export const removeAdminSession = () => {
  localStorage.removeItem("jos");
  localStorage.removeItem("josracadmin");
};

export const removeUserSession = () => {
  localStorage.clear();
  localStorage.removeItem("token");
};
