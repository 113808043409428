import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import API_URL from "../../../config/index";
import { Button, Form, Spinner } from "react-bootstrap";
import { getToken } from "../../../utils/Common";
function UpdateServices() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [image, setImage] = useState(null);
  const [data, setData] = useState({
    name: "",
    description: "",
  });

  const url = `${API_URL}/services/update/${id}`;

  const token = getToken();
  const axiosCreate = axios.create({
    headers: {
      authorization: "Bearer " + token,
    },
  });
  const url2 = `${API_URL}/services/${id}`;
  useEffect(() => {
    setLoading(true);
    axios.get(url2).then(({ data }) => {
      setData(data?.payload[0]);
      // console.log(data)
      setLoading(false);
    });
  }, [url2]);

  const handleSubmit = (e) => {
    // const formData = new FormData();
    // console.log(image)
    // formData.append("title", data?.title);
    e.preventDefault();
    setShow(false);
    setLoading(true);
    axios
      .put(url, {
        name: data?.name,
        description: data?.description,
      })
      .then(({ data }) => {
        setMessage(data?.message);
        setLoading(false);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        // console.log(err?.response?.data?.message);
        setLoading(false);
        setShow(true);
      });
  };
  const handle = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
    console.log(newData);
  };
  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Update Services</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">Update Services</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profil table-responsive">
          <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Go back
          </button>
          {loading ? (
            <div className="text-center mt-5 pt-5">
              <Spinner animation="border" className="text-center" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              {err && show ? (
                <div
                  class="alert alert-danger text-center container w-50"
                  role="alert"
                >
                  <strong>{err}</strong>
                </div>
              ) : (
                <div style={{ display: "none" }}>{err}</div>
              )}

              {message ? (
                <div class="alert alert-success text-center " role="alert">
                  <strong>{message}</strong>
                  <p style={{ display: "none" }}>
                    {setTimeout(() => navigate("/services"), 3000)}
                  </p>
                </div>
              ) : (
                <div></div>
              )}
              <h2 className="display-5 text-center m-5">Update Services</h2>
              <Form
                className="w-50 mx-auto"
                onSubmit={(e) => handleSubmit(e)}
                //   enctype="multipart/form-data"
                method="post"
                style={{ width: "50%", margin: "auto" }}
              >
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name of Product</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="enter name of services"
                    className="w-100"
                    value={data?.name}
                    onChange={(e) => handle(e)}
                    name="name"
                    // required
                  />
                </Form.Group>
                <Form.Label className="label">Services Description</Form.Label>
                <textarea
                  className="form-control"
                  placeholder="Enter Services Description..."
                  style={{ height: 150 }}
                  value={data?.description}
                  name="description"
                  onChange={(e) => handle(e)}
                ></textarea>
                <Button variant="primary" type="submit">
                  Update Services
                </Button>
                {/* <Button variant="outline-warning">Go Back</Button> */}
              </Form>
            </>
          )}
        </section>
      </main>
      {/* <!-- End #main --> */}
    </>
  );
}

export default UpdateServices;
