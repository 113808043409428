import React, { useState, useEffect } from "react";
import { Table, Button, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import axios from "axios";
import API_URL from "../../config/index";
import ExcelExport from "react-html-table-to-excel";
import ModalDelete from "../ModalDelete";
import styled from "styled-components";
function SingleServices() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [message, setMessage] = useState(null);
  const [productid, setId] = useState(null);
  const [err, setErr] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  // const url = `${API_URL}/product`;
  const url = API_URL + "/services/" + id;
  useEffect(() => {
    setLoading(true);
    axios.get(url).then(({ data }) => {
      setProduct(data?.payload[0]);
      // console.log(data)
      setLoading(false);
    });
  }, [url]);

  const handleId = (id, e) => {
    e.preventDefault();
    console.log(id);
    // setId(id);
  };

  console.log(product);
  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Single Service</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}
        <button className="btn btn-primary" onClick={() => navigate(-1)}>
          Go back
        </button>

        {/* <h2 className="display-5 text-center m-5">All Products</h2> */}
        {loading ? (
          <div className="mt-5 pt-5">
            <Spinner
              animation="border"
              className="text-center offset-md-6"
              role="status"
            >
              <span className="visually-hidden">loading.....</span>
            </Spinner>
          </div>
        ) : (
          <>
            <ServicesContainer className="pt-4 mx-auto">
              <div className="">
                <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                  <div className="icon-box">
                    <div className="icon">
                      <i className="bx bxl-dribbble"></i>
                    </div>
                    <h4>
                      <Link to={`/services/update/${product?.id}`}>{product?.name}</Link>
                    </h4>
                    <p>{product?.description}</p>
                  </div>
                </div>
              </div>
            </ServicesContainer>
          </>
        )}
      </main>
    </>
  );
}

const ServicesContainer = styled.div`
  .icon-box {
    text-align: center;
    background: #18d26e;
    border-color: #18d26e;
    padding: 80px 20px;
    transition: all ease-in-out 0.3s;
  }
  .icon-box .icon {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    background: #18d26e;
    border-radius: 5px;
    transition: all 0.3s ease-out 0s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transform-style: preserve-3d;
  }
  .icon-box .icon i {
    color: #fff;
    font-size: 28px;
  }
  .icon-box .icon::before {
    position: absolute;
    content: "";
    left: -8px;
    top: -8px;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 5px;
    transition: all 0.3s ease-out 0s;
    transform: translateZ(-1px);
  }
  .icon-box h4 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
  }
  .icon-box h4 a {
    color: #fff;
  }
  .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  .icon-box {
    background: #18d26e;
    border-color: #18d26e;
  }
  .icon-box .icon {
    background: #fff;
  }
  .icon-box .icon i {
    color: #18d26e;
  }
  .icon-box:hover .icon::before {
    background: #35e888;
  }
  .icon-box h4 a,
  .icon-box p {
    color: #fff;
  }
`;

export default SingleServices;
