import React, { useState, useEffect } from "react";
import { handleMoving } from "../handles";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import API_URL from "../../../config";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
// import ReactExport from "react-export-excel";
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import ExcelExport from "react-html-table-to-excel";

// import "../../../App.css"
// import "./style.css"
function GrossSalary() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [gross, setGross] = useState(null);
  const [message, setMessage] = useState(null);
  const [err, setErr] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const url = `${API_URL}/payroll/gross`;
  useEffect(() => {
    setLoading(true);
    axios.get(url).then(({ data }) => {
      setGross(data?.payload);
      setLoading(false);
      // console.log(data)
    });
  }, [url]);

  const calculateGrossSalary = gross?.reduce((prev, cur) => {
    // console.log(prev + cur?.totalcost);
    return prev + cur?.grosssalary;
  }, 0);
  const calculateBasicSalary = gross?.reduce((prev, cur) => {
    // console.log(prev + cur?.totalcost);
    return prev + cur?.basicsalary;
  }, 0);
  const calculateRechargecard = gross?.reduce((prev, cur) => {
    // console.log(prev + cur?.totalcost);
    return prev + cur?.rechargecard;
  }, 0);
  const calculateBonus = gross?.reduce((prev, cur) => {
    // console.log(prev + cur?.totalcost);
    return prev + cur?.bonus;
  }, 0);
  const calculateAllowance = gross?.reduce((prev, cur) => {
    // console.log(prev + cur?.totalcost);
    return prev + cur?.allowance;
  }, 0);

  const naira = "\u20A6";

  function handleExport() {
    let tableClone = document.querySelector("#emp-table").clone();
    tableClone.find("no-export").each(function (info) {
      info.remove();
    });
    document.querySelector(".bodies").html(tableClone);
  }

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Payroll</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">Gross Salary</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile p-5 table-responsive">
          <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Go back
          </button>
          <div className="pb-4">
            <Link to="/create-gross" className="btn btn-primary">
              create gross salary
            </Link>
          </div>

          <table class="table table-responsive" id="emp-table">
            <thead>
              <tr>
                <th scope="col">S/N</th>
                <th scope="col">Name of Employee</th>
                <th scope="col">Gross Salary</th>
                <th scope="col">Basic Salary</th>
                <th scope="col">Rechard Card Allowance</th>
                <th scope="col">Bonus</th>
                <th scope="col">Allowance</th>
                <th scope="col" className="no-export">
                  Edit
                </th>
                <th scope="col" className="no-export">
                  View
                </th>
                <th scope="col" className="no-export">
                  Update
                </th>
              </tr>
            </thead>
            {loading ? (
              <div className="text-center mt-5 pt-5">
                <Spinner
                  animation="border"
                  className="text-center"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                <tbody>
                  {gross?.length === 0 ? (
                    <>
                      <h5 className="text-center p-4">
                        No Gross Salary Uploaded
                      </h5>
                    </>
                  ) : (
                    <>
                      {gross?.map((data) => {
                        return (
                          <tr>
                            <th scope="row">{data?.serialnumber}</th>
                            <td>{data?.nameofemployee}</td>
                            {/* <td>{data?.schoolstatus}</td> */}
                            <td>
                              {naira}
                              {data?.grosssalary}
                            </td>
                            <td>
                              {naira}
                              {data?.basicsalary}
                            </td>
                            <td>
                              {naira}
                              {data?.rechargecard}
                            </td>
                            <td>
                              {naira}
                              {data?.bonus}
                            </td>
                            <td>
                              {naira}
                              {data?.allowance}
                            </td>
                            <td className="no-export">
                              <Link
                                to={"/gross/" + data?._id}
                                className="btn btn-primary"
                              >
                                Edit
                              </Link>
                            </td>
                            <td className="no-export">
                              <Link
                                to={"/gross/view/" + data?._id}
                                className="btn btn-primary"
                              >
                                View
                              </Link>
                            </td>
                            <td className="no-export">
                              <Link
                                to={"/gross/update/" + data?._id}
                                className="btn btn-primary"
                              >
                                Update
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                      <tr className="bg-dark text-white">
                        <th scope="row" colspan="2" style={{ width: "100%" }}>
                          Grand Total
                        </th>
                        {/* <td rowspan="2"></td> */}
                        <td rowspan="">
                          {naira}
                          {calculateGrossSalary?.toLocaleString()}
                        </td>
                        <td rowspan="">
                          {naira}
                          {calculateBasicSalary?.toLocaleString()}
                        </td>
                        <td rowspan="">
                          {naira}
                          {calculateRechargecard?.toLocaleString()}
                        </td>
                        <td rowspan="">
                          {naira}
                          {calculateBonus?.toLocaleString()}
                        </td>
                        <td rowspan="">
                          {naira}
                          {calculateAllowance?.toLocaleString()}
                        </td>
                        <td rowspan=""></td>
                        <td rowspan=""></td>
                        <td rowspan=""></td>
                      </tr>
                    </>
                  )}
                </tbody>
              </>
            )}
          </table>
          <ExcelExport
            // onClick={handleExport}
            className="btn btn-info"
            table="emp-table"
            sheet="Sheet"
            buttonText="export to excel"
            filename="Staff Gross Salary"
            id="btnExport"
          />
        </section>
      </main>
      {/* <!-- End #main --> */}
    </>
  );
}

export default GrossSalary;
