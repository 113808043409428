import React, { useState, useEffect } from "react";
import EditProfile from "../profile/EditProfile";
import ProfileOverview from "../profile/ProfileOverview";
import ProfilePasswordUpdate from "../profile/ProfilePasswordUpdate";
import Header from "./Header";
import Sidebar from "./Sidebar";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import API_URL from "../../config";
import { getAdmin } from "../../utils/Common";
import ProfileSetings from "../profile/ProfileSettings";

console.log(getAdmin());
const adminDetails = getAdmin();

function AdminProfile() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState();
  const url = `${API_URL}/admin/single`;
  useEffect(() => {
    setLoading(true);
    axios.post(url, { id: adminDetails?._id }).then(({ data }) => {
      setUser(data?.payload[0]);
      setLoading(false);
      console.log(data);
    });
  }, [url]);

  return (
    <>
      <Header />
      <Sidebar />

      <main id="main" className="main" style={{ background: "#fff" }}>
        <div className="pagetitle">
          <h1>Profile</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Home</a>
              </li>
              <li className="breadcrumb-item">Users</li>
              <li className="breadcrumb-item active">Profile</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile">
          <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Go back
          </button>
          <div className="row">
            <div className="col-xl-4">
              <div className="card">
                <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                  <img
                    src={`${user?.image}`}
                    alt="No image Uploaded"
                    className="rounded-circle"
                  />
                  <h2>{user?.username}</h2>
                  {/* <h3>Web Designer</h3> */}
                  <div className="social-links mt-2">
                    <a href={user?.twitter} className="twitter">
                      <i className="bi bi-twitter"></i>
                    </a>
                    <a href={user?.facebook} className="facebook">
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a href={user?.instagram} className="instagram">
                      <i className="bi bi-instagram"></i>
                    </a>
                    <a href={user?.linkedin} className="linkedin">
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-8">
              <div className="card">
                <div className="card-body pt-3">
                  {/* <!-- Bordered Tabs --> */}
                  <ul className="nav nav-tabs nav-tabs-bordered">
                    <li className="nav-item">
                      <button
                        className="nav-link active"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-overview"
                      >
                        Overview
                      </button>
                    </li>

                    <li className="nav-item">
                      <button
                        className="nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-edit"
                      >
                        Edit Profile
                      </button>
                    </li>

                    <li className="nav-item">
                      <button
                        className="nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-change-password"
                      >
                        Change Password
                      </button>
                    </li>

                    <li className="nav-item">
                      <button
                        className="nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#settings"
                      >
                        Settings
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content pt-2">
                    <ProfileOverview />

                    <EditProfile />

                    <ProfilePasswordUpdate />

                    <ProfileSetings />
                  </div>
                  {/* <!-- End Bordered Tabs --> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}
    </>
  );
}

export default AdminProfile;
