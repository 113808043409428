import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import API_URL from "../../../config";
import "./success.css";

function Success() {
  const navigate = useNavigate();
  const [message, setMessage] = useState(false);
  const [err, setErr] = useState(null);
  const id = useParams();
  const url = `${API_URL}/auth/authentication/activate/${id.id}`;
  useEffect(() => {
    console.log(id);
    axios
      .get(url)
      .then(({ data }) => {
        // console.log(data);
        setErr(null);
        setMessage(data?.message);
      })
      .catch((error) => {
        // console.log(error);
        setErr(error?.response?.data?.message);
        // setMessage(null)
      });
  }, [url]);
  return (
    <>
      <body className="home">
        <div class="container-mains">
          <div class="circle-border"></div>
          <div class="circle">
            {message ? (
              <>
                <div class="success"></div>
                <div class="verify-completed">
                  <h3 className="">
                    Email verification has been completed sucessfully
                  </h3>
                  <button
                    className="btn btn-success"
                    onClick={() => navigate("/auth")}
                  >
                    Login
                  </button>
                </div>
              </>
            ) : (
              <>
                <div class="error"></div>

                <div class="verify-completed">
                  <h3 className="">Expired link or has already been used</h3>
                </div>
              </>

            )}
            {/* {err ? (
              <div class="verify-completed">
                <h3 className="">Expired link or has already been used</h3>
              </div>
            ) : (
              <div class="verify-completed">
                <h3 className="">
                  Email verification has been completed sucessfully
                </h3>
                <button className="btn btn-success">
                  <a href="/auth">Login</a>
                </button>
              </div>
            )} */}
          </div>
        </div>
      </body>
    </>
  );
}

export default Success;
