import React, { useState, useEffect } from "react";
import { handleMoving } from "../handles";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import API_URL from "../../../config";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
// import ReactExport from "react-export-excel";
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import ExcelExport from "react-html-table-to-excel";

// import "../../../App.css"
// import "./style.css"
function SingleInflowProduct() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [single, setSingle] = useState(null);
  const [message, setMessage] = useState(null);
  const [err, setErr] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const url = `${API_URL}/product/inflow-product/${id}`;
  useEffect(() => {
    setLoading(true);
    axios.get(url).then(({ data }) => {
      setSingle(data?.payload[0]);
      setLoading(false);
      console.log(data);
    });
  }, [url]);

  console.log(single);
  // const today = single?.updatedAt();
  // console.log(today)
  // console.log(
  //   new Intl.DateTimeFormat("en-US", {
  //     year: "numeric",
  //     month: "2-digit",
  //     day: "2-digit",
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     second: "2-digit",
  //   }).format(today)
  // );

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Single Inflow Product</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">Inflow product</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile p-5 table-responsive">
          <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Go back
          </button>
          {/* <h2>Services Name: {single?.services?.name}</h2> */}
          <hr />
          <h2>Service Type: {single?.servicetype}</h2>
          <hr />
          <h2>Project Duration: {single?.projectduration}</h2>
          <hr />
          <h2>Total Cost: {single?.totalcost?.toLocaleString()}</h2>
          <hr />
          <h2>Amount Paid: {single?.amountpaid?.toLocaleString()}</h2>
          <hr />
          <h2>Outstanding: {single?.outstanding?.toLocaleString()}</h2>
          <hr />
          <h2>Desctiption No: {single?.description}</h2>
          <hr />
          <h2>Last Updated: {single?.updatedAt}</h2>
          <hr />

          <Link
            to={"/update-services/product/" + single?.id}
            className="btn btn-primary"
          >
            Edit
          </Link>
        </section>
      </main>
      {/* <!-- End #main --> */}
    </>
  );
}

export default SingleInflowProduct;
