// import { useNavigate } from "react-router";
// import { Route } from "react-router-dom";
// import { getToken } from "./Common";

// const PrivateRoute = ({ component: Component, ...rest }) => {
//   const navigate = useNavigate();
//   return (
//     <Route
//       {...rest}
//       component={(props) => {
//         const token = getToken();
//         if (token) {
//           return <Component {...props} />;
//         }else{
//           return navigate("/login")
//         }
//       }}
//     />
//   );
// };

// export default PrivateRoute;

import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getToken } from "./Common";

const PrivateRoute = () => {
  const auth = getToken(); // determine if authorized, from context or however you're doing it
  // const token = window.localStorage.getItem("token");

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return auth ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/auth" />
  );
};

export default PrivateRoute;
