import React, { useState, useEffect } from "react";
import { handleMoving } from "../handles";
import API_URL from "../../../config";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Spinner } from "react-bootstrap";
import axios from "axios";
function Outflow() {
  const navigate = useNavigate();
  const [outflow, setOutflow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [servicesinflow, setServicesInflow] = useState(null);
  const [productinflow, setProductInflow] = useState(null);
  const url3 = `${API_URL}/services/outflow/all`;
  useEffect(() => {
    setLoading(true);
    axios.get(url3).then(({ data }) => {
      setOutflow(data?.payload);
      setLoading(false);
      // console.log(data);
    });
  }, [url3]);

  const url = `${API_URL}/services/inflow-services/all`;
  const url2 = `${API_URL}/product/inflow-product/all`;
  useEffect(() => {
    setLoading(true);
    axios.get(url).then(({ data }) => {
      setServicesInflow(data?.payload);
      setLoading(false);
      console.log(data);
    });
  }, [url]);
  useEffect(() => {
    setLoading(true);
    axios.get(url2).then(({ data }) => {
      console.log(data);
      setProductInflow(data?.payload[0]);
      setLoading(false);
    });
  }, [url2]);
  const calculateTotalServices = servicesinflow?.reduce((prev, cur) => {
    // console.log(prev + cur?.totalcost);
    return prev + cur?.totalcost;
  }, 0);
  const calculateTotalProduct = productinflow?.reduce((prev, cur) => {
    // console.log(prev + cur?.totalcost);
    return prev + cur?.totalcost;
  }, 0);

  const outflowPrice = outflow?.reduce((prev, cur) => {
    // console.log(prev + cur?.totalcost);
    return prev + cur?.totalamount;
  }, 0);

  const calculateTotalBalance = () => {
    return calculateTotalProduct + calculateTotalServices - outflowPrice;
  };

  // console.log(calculateTotalBalance())
  console.log(calculateTotalProduct)  
  console.log(calculateTotalServices)
  console.log(outflowPrice);
  console.log(productinflow)
  const naira = "\u20A6";


  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Balance</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">Balance</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile p-5 table-responsive">
          <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Go back
          </button>
          <h3>Total Balances</h3>
          <div className="row">
            <div className="col-md-6">
              <h3 className="offset-md-6 mt-4">Grand-Total:</h3>
            </div>
            <div className="col-md-6">
              {loading ? (
                <></>
              ) : (
                <>
                  <h3 className="offset-md-6 mt-4">
                    {naira}
                    {calculateTotalBalance().toLocaleString()}
                  </h3>
                </>
              )}
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}
    </>
  );
}

export default Outflow;
