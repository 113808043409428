import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./Components/Admin/HomePage";
import ProductsLinking from "./Components/Admin/ProductsLinking";
import VisitSchoolJosrac from "./Components/Admin/schools/VisitSchoolJosrac";
import SuscribedSchools from "./Components/Admin/schools/SuscribedSchool";
import Inflow from "./Components/Admin/flows/Inflow";
import Outflow from "./Components/Admin/flows/Outflow";
import Balance from "./Components/Admin/flows/Balance";
import AdminProfile from "./Components/Admin/AdminProfile";
import CreateVisitJosrac from "./Components/Admin/schools/create/josrac/CreateVisitJosrac";
import CreateSuscribedSchool from "./Components/Admin/schools/create/josrac/CreateSuscribedSchool";
import UpdateSuscribedSchool from "./Components/Admin/schools/create/josrac/UpdateSuscribedSchool";
import UpdateVisitJosrac from "./Components/Admin/schools/create/josrac/UpdateVisitJosrac";
import CreateProduct from "./Components/Admin/schools/create/josrac/CreateProduct";
import CreateServices from "./Components/Admin/services/CreateServices";
import AllProduct from "./Components/Admin/AllProduct";
import UpdateProduct from "./Components/Admin/schools/create/josrac/UpdateProduct";
import AllServices from "./Components/Admin/AllServices";
import ServicesProduct from "./Components/Admin/services/ServicesProduct";
import CreateServicesProduct from "./Components/Admin/services/CreateServicesProduct";
import UpdateServices from "./Components/Admin/services/UpdateServices";
import UpdateServicesProduct from "./Components/Admin/services/UpdateServicesProduct";
import CreateInflowProduct from "./Components/Admin/flows/CreateInflowProduct";
import CreateInflowServices from "./Components/Admin/flows/CreateInflowServices";
import UpdateInflowProduct from "./Components/Admin/flows/UpdateInflowProduct";
import UpdateInflowServices from "./Components/Admin/flows/UpdateInflowServices";
import CreateOutflow from "./Components/Admin/services/CreateOutflow";
import UpdateOutflow from "./Components/Admin/services/UpdateOutflow";
import LoginRegister from "./Components/Admin/auth/LoginRegister";
import Success from "./Components/Admin/auth/Success";
import ForgotPassword2 from "./Components/Admin/auth/ForgotPassword2";
import CreateSuccess from "./Components/Admin/auth/CreateSuccess";
import ForgotPassword from "./Components/Admin/auth/ForgotPassword";
import CreateSuccessPaasword from "./Components/Admin/auth/CreateSuccessPassword";
// import "bootstrap/dist/css/bootstrap.min.css";
import PrivateRoute from "./utils/PrivateRoute";
import GrossSalary from "./Components/Admin/payroll/GrossSalary";
import TotalDeduction from "./Components/Admin/payroll/TotalDeduction";
import CreateDeduction from "./Components/Admin/payroll/CreateDeduction";
import SingleJosracSuscribed from "./Components/Admin/schools/SingleJosracSuscribed";
import SingleJosracVisited from "./Components/Admin/schools/SingleJosracVisited";
import SingleServicesProduct from "./Components/Admin/services/SingleServicesProduct";
import SingleInflowProduct from "./Components/Admin/flows/SingleInflowProduct";
import SingleInflowServices from "./Components/Admin/flows/SingleInflowServices";
import SingleOutflow from "./Components/Admin/flows/SingleOutflow";
import UpdateDeduction from "./Components/Admin/payroll/UpdateDeduction";
import SingleDeduction from "./Components/Admin/payroll/SingleDeduction";
import CreateUpdateVisitJosrac from "./Components/Admin/schools/create/josrac/CreateUpdateVisitJosrac";
import CreateUpdateSuscribedSchoolJosrac from "./Components/Admin/schools/create/josrac/CreateUpdateSuscribedSchool";
import CreateUpdateServicesProduct from "./Components/Admin/services/CreateUpdateServicesProduct";
import InflowHome from "./Components/Admin/flows/InflowHome";
import InflowProduct from "./Components/Admin/flows/InflowProduct";
import InflowServices from "./Components/Admin/flows/InflowServices";
import UpdateCreateGrossSalary from "./Components/Admin/payroll/UpdateCreateGrossSalary";
import UpdateCreateDeduction from "./Components/Admin/payroll/UpdateCreateDeduction";
import CreateGrossSalary from "./Components/Admin/payroll/CreateGrossSalary";
import SingleGross from "./Components/Admin/payroll/SingleGross";
import UpdateGross from "./Components/Admin/payroll/UpdateGross";
import SingleProduct from "./Components/Admin/SingleProduct";
import SingleServices from "./Components/Admin/SingleServices";
import AllRegistered from "./Components/Admin/schools/create/josrac/AllRegistered";
import RegisteredSchool from "./Components/Admin/schools/RegisteredSchool";
import AllVisit from "./Components/Admin/schools/create/josrac/AllVisit";
import CreateRegisteredSchool from "./Components/Admin/schools/create/josrac/CreateRegisteredSchool";
import CreateUpdateSucribed from "./Components/Admin/schools/create/josrac/CreateUpdateSuscribedSchool";
import SingleJosracRegistered from "./Components/Admin/schools/SingleJosracRegistered";
import UpdateRegisteredJosrac from "./Components/Admin/schools/create/josrac/UpdateRegisteredJosrac";
import CreateUpdateRegisteredJosrac from "./Components/Admin/schools/create/josrac/CreateUpdateRegisteredJosrac";

function App() {
  return (
    // <>
    //   <Header />
    //   <Sidebar />
    // </>
    <Router>
      <Routes>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<HomePage />} />
        </Route>
        {/* <Route path="/" exact element={<HomePage />} /> */}
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/visit-schools/:id" element={<VisitSchoolJosrac />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/services/:id" element={<ServicesProduct />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/product/:id" element={<ProductsLinking />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/suscribed-schools/:id" element={<SuscribedSchools />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/inflow" element={<InflowHome />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/inflow-product/:id" element={<InflowProduct />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/inflow-services/:id" element={<InflowServices />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/outflow" element={<Outflow />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/balance" element={<Balance />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/profile" element={<AdminProfile />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/create-visit-josrac/:id"
            element={<CreateVisitJosrac />}
          />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/update-visited-school/:id"
            element={<CreateUpdateVisitJosrac />}
          />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/update-registered-school/:id"
            element={<CreateUpdateRegisteredJosrac />}
          />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/update-suscribed-school/:id"
            element={<CreateUpdateSuscribedSchoolJosrac />}
          />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/create-services-product/:id"
            element={<CreateServicesProduct />}
          />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route
            path={"/update-services-product/:id"}
            element={<CreateUpdateServicesProduct />}
          />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/create-suscribe-josrac/:id"
            element={<CreateSuscribedSchool />}
          />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route
            // path="/update-suscribed-school/:id"
            path="/edit-suscribed-school/:id"
            element={<UpdateSuscribedSchool />}
          />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/update-suscribed-school/:id"
            element={<CreateUpdateSucribed />}
          />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/update-visit-schoolJosrac/:id"
            element={<UpdateVisitJosrac />}
          />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/update-registered-schoolJosrac/:id"
            element={<UpdateRegisteredJosrac />}
          />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/create-product" element={<CreateProduct />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/create-services" element={<CreateServices />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/services/update/:id" element={<UpdateServices />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/update-services/product/:id"
            element={<UpdateServicesProduct />}
          />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/products" element={<AllProduct />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/product/view/:id" element={<SingleProduct />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/services/view/:id" element={<SingleServices />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/services" element={<AllServices />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/product/update/:id" element={<UpdateProduct />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/create-inflow-product/:id"
            element={<CreateInflowProduct />}
          />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/update-inflow-product/:id"
            element={<UpdateInflowProduct />}
          />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/create-inflow-services/:id"
            element={<CreateInflowServices />}
          />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/update-inflow-services/:id"
            element={<UpdateInflowServices />}
          />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/update-outflow/:id" element={<UpdateOutflow />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/outflow" element={<Outflow />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/create-outflow" element={<CreateOutflow />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/gross" element={<GrossSalary />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/gross/:id" element={<UpdateGross />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/deduction" element={<TotalDeduction />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/create-deduction" element={<CreateDeduction />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/create-gross" element={<CreateGrossSalary />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/gross/view/:id" element={<SingleGross />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/gross/update/:id" element={<UpdateCreateGrossSalary />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/deduction/update/:id" element={<UpdateCreateDeduction />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/deduction/:id" element={<UpdateDeduction />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/view-suscribed-school/:id"
            element={<SingleJosracSuscribed />}
          />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/view-visited-school/:id"
            element={<SingleJosracVisited />}
          />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/view-registered-school/:id"
            element={<SingleJosracRegistered />}
          />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/view-services-product/:id"
            element={<SingleServicesProduct />}
          />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/view-inflow-product/:id"
            element={<SingleInflowProduct />}
          />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/view-inflow-services/:id"
            element={<SingleInflowServices />}
          />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/view-single-outflow/:id" element={<SingleOutflow />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/view-single-deduction/:id"
            element={<SingleDeduction />}
          />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/all-registered/:id"
            element={<AllRegistered />}
          />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/registered-schools/:id"
            element={<RegisteredSchool />}
          />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/all-visited/:id"
            element={<AllVisit />}
          />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/create-registered-josrac/:id"
            element={<CreateRegisteredSchool />}
          />
        </Route>

        {/* Authentication start */}
        <Route path="/auth" element={<LoginRegister />} />
        <Route path="/authentication/activate/:id" element={<Success />} />
        <Route path="/reset-password" element={<ForgotPassword2 />} />
        <Route path="/create-success" element={<CreateSuccess />} />
        <Route
          path="/authentication/:admin/:token"
          element={<ForgotPassword />}
        />
        <Route path="/success-password" element={<CreateSuccessPaasword />} />
        {/* Authentication end */}
      </Routes>
    </Router>
  );
}

export default App;
