import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../../Header";
import Sidebar from "../../../Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import API_URL from "../../../../../config/index";
import { Button, Form, Spinner } from "react-bootstrap";
import { getToken } from "../../../../../utils/Common";
import styled from "styled-components"
function AllVisit() {
  const id = useParams();
  const navigate = useNavigate();
  const [visit, setVisit] = useState(null)
  const [message, setMessage] = useState(null);
  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [image, setImage] = useState(null);
  const [select, setSelect] = useState("");

  // handling state for the input field text
  const [data, setData] = useState({
    name: "",
    category: "",
    subcategory: "",
    description: "",
  });
  const url = `${API_URL}/product/update/${id}`;

  const token = getToken();
  const axiosCreate = axios.create({
    headers: {
      authorization: "Bearer " + token,
    },
  });

  const url2 = `${API_URL}/product/visited/product/${id.id}`;
  useEffect(() => {
    setLoading(true);
    axios.get(url2).then(({ data }) => {
      // console.log(data)
      setLoading(false);
      setVisit(data?.payload[0])
      console.log(data?.payload[0])
    });
  }, [url2]);
  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Step One</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">Step One</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile">
        <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Go back
        </button>
          {loading ? (
            <div className="text-center mt-5 pt-5">
              <Spinner animation="border" className="text-center" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              <div className="section-title">
                <h2>Step 1</h2>

                <p>Choose one of the following visited school below</p>
              </div> 
              <div>
                {visit?.map((data)=>(             
                  <LinkContainer key={data?._id}>
                      <li><LinkStyle onClick={()=> navigate("/create-registered-josrac/"+ id.id, {
                        state: {id: data?._id, nameofschool: data?.nameofschool}
                      })}>{data?.nameofschool?.toUpperCase()}</LinkStyle></li>
                  </LinkContainer>
                ))}
              </div>
            </>
          )}

        </section>
      </main>
      {/* <!-- End #main --> */}
    </>
  );
}

const LinkContainer = styled.ul`

`

const LinkStyle= styled.a`
    text-decoration: none;
    font-weight: bold;
`

export default AllVisit;
