import React, { useState, useEffect } from "react";
import { handleMoving } from "../handles";
import { Spinner, Button } from "react-bootstrap";
import axios from "axios";
import API_URL from "../../../config";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
// import ReactExport from "react-export-excel";
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import ExcelExport from "react-html-table-to-excel";
import ModalDelete from "../../ModalDelete";

// import "../../../App.css"
// import "./style.css"
function SingleJosracVisited() {
  const navigate = useNavigate();
  const [ids, setId] = useState(null);
  const [school, setSchool] = useState(null)
  const { id } = useParams();
  const [single, setSingle] = useState(null);
  const [single2, setSingle2] = useState(null);
  const [message, setMessage] = useState(null);
  const [err, setErr] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const url = `${API_URL}/product/visited/${id}`;
  const url2 = `${API_URL}/product/visited-updated/${id}`;
  useEffect(() => {
    setLoading(true);
    axios.get(url).then(({ data }) => {
      setSingle(data?.payload[0]);
      setLoading(false);
      // console.log(data)
    });
  }, [url]);
  useEffect(() => {
    setLoading(true);
    axios.get(url2).then(({ data }) => {
      setSingle2(data?.payload[0]);
      setLoading(false);
      // console.log(data)
    });
  }, [url2]);

  console.log(single2);
  // const today = single?.updatedAt();
  // console.log(today)
  // console.log(
  //   new Intl.DateTimeFormat("en-US", {
  //     year: "numeric",
  //     month: "2-digit",
  //     day: "2-digit",
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     second: "2-digit",
  //   }).format(today)
  // );

  //   var now = "2022-06-16T15:48:12.205Z".toISOString();
  //   console.log(now)
  //   var date = now.toLocaleDateString();
  //   var time = now.toLocaleTimeString();
  //   console.log(date + " " + time);

  
  const handleId = (ids,schoolname, e) => {
    e.preventDefault();
    setId(ids);
    setSchool(schoolname)
  };
  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Single Visited School</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">Visited</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile p-5 table-responsive">
          <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Go back
          </button>
          {loading ? (
            <>
              <div className="text-center mt-5 pt-5">
                <Spinner
                  animation="border"
                  className="text-center"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            </>
          ) : (
            <>
            {message ? (
              <div class="alert alert-success text-center " role="alert">
                <strong>{message}</strong>
                <p style={{ display: "none" }}>
                  {setTimeout(() => navigate("/visit-schools/" + single?.product?._id), 3000)}
                  {/* {window.location.reload()} */}
                </p>
              </div>
            ) : (
              <div></div>
            )}
              <h2>Product: {single?.product?.name}</h2>
              <hr />
              <h2>Name-of-school: {single?.nameofschool}</h2>
              <hr />
              <h2>Address: {single?.address}</h2>
              <hr />
              <h2>Phone-No: {single?.number}</h2>
              <hr />
              <h2>Recipient: {single?.recipient}</h2>
              <hr />
              <h2>feedback: {single?.feedback?.map((data)=>(<>{data}</>))}</h2>
              <hr />
              <h2>Date: {single?.date}</h2>
              <hr />
              <h2>
                Last Updated:
                {single?.updatedAt?.replace(/T/, " ")?.replace(/\..+/, "")}
              </h2>
              <hr />
              <Link
                to={"/update-visit-schoolJosrac/" + single?.id}
                className="btn btn-primary"
              >
                Edit
              </Link><br />
              <Button  
                onClick={(e) => handleId(single?.id,single?.nameofschool, e)} 
                variant="dark"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                >
                Delete
              </Button>

              <br /> <br />
              <hr />
              {single2?.map((data) => {
                return (
                  <>
                    <h2>Product: {data?.product?.name}</h2>
                    <hr />
                    <h2>Name-of-school: {data?.nameofschool}</h2>
                    <hr />
                    <h2>Address: {data?.address}</h2>
                    <hr />
                    <h2>Phone-No: {data?.number}</h2>
                    <hr />
                    <h2>Recipient: {data?.recipient}</h2>
                    <hr />
                    <h2>feedback: {data?.feedback?.map((data)=>(<>{data}</>))}</h2>
                    <hr />
                    <h2>Date: {data?.date}</h2>
                    <hr />
                    {/* <h2>Last Updated: {data?.updatedAt?.split("T",1)} TIME {data?.updatedAt?.split("Z",0)}</h2> */}
                    <h2>
                      Last Updated:
                      {data?.updatedAt?.replace(/T/, " ")?.replace(/\..+/, "")}
                    </h2>
                    <hr />

                    <Link
                      to={"/update-visit-schoolJosrac/" + data?.id}
                      className="btn btn-primary"
                    >
                      Edit
                    </Link>
                  </>
                );
              })}
            </>
          )}
            <ModalDelete
              title="Delete Product"
              writeup={`Are you sure you want to permanently delete ${school} school and it's content?`}
              id={ids}
              url={`${API_URL}/product/visited/delete/`}
              setMessage={setMessage}
              setLoading={setLoading}
              setErr={setErr}
            />
        </section>
      </main>
      {/* <!-- End #main --> */}
    </>
  );
}

export default SingleJosracVisited;
