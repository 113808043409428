import React, {useState, useEffect} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { handleMoving } from "./handles";
import Header from "./Header";
import Sidebar from "./Sidebar";
import styled from "styled-components"
import API_URL from "../../config";
import axios from "axios";
import {Spinner} from "react-bootstrap";

function ProductsLinking() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [visit, setVisit] = useState(null);
  const [suscribed, setSuscribed] = useState(null);
  const [registered, setRegistered] = useState(null)
  const [message, setMessage] = useState(null);
  const [err, setErr] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const url = `${API_URL}/product/visited/product/${id}?three=true`;
  useEffect(() => {
    setLoading(true);
    axios.get(url).then(({ data }) => {
      console.log(data?.payload[0])
      setVisit(data?.payload[0]);
      // console.log(data)
      setLoading(false);
    }).catch((error)=>{
      setLoading(false)
      setErr(error?.response?.data?.message);
    })
  }, [url]);

  const url1 = `${API_URL}/product/suscribed/product/${id}?three=true`;
  useEffect(() => {
    setLoading(true);
    axios.get(url1).then(({ data }) => {
      console.log(data?.payload[0])
      setSuscribed(data?.payload[0]);
      // console.log(data)
      setLoading(false);
    }).catch((error)=>{
      setLoading(false)
      setErr(error?.response?.data?.message);
    })
  }, [url1]);

  const url2 = `${API_URL}/product/register/product/${id}?three=true`;
  useEffect(() => {
    setLoading(true);
    axios.get(url2).then(({ data }) => {
      console.log(data?.payload[0])
      setRegistered(data?.payload[0]);
      // console.log(data)
      setLoading(false);
    }).catch((error)=>{
      setLoading(false)
      setErr(error?.response?.data?.message);
    })
  }, [url2]);

  console.log(visit)
  console.log("departed")
  console.log(registered)
  console.log("departed1")
  console.log(suscribed)
  

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Products</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Home</a>
              </li>
              {/* <li className="breadcrumb-item"></li> */}
              <li className="breadcrumb-item active">Product JOSRAC </li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile p-5">
          <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Go back
          </button>
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="section-title">
              <h2 className="">Recent Visit</h2>
              <button className="btn btn-warning btn-sm" onClick={()=>navigate("/visit-schools/" + id)}>View All</button>
            </div>
              <VisitedTable className="table table-striped">
              <thead>
              <tr>
                <th scope="col">S/N</th>
                <th scope="col">Name of schools</th>
                <th scope="col">Location Address</th>
                <th scope="col">Mobile No</th>
                <th scope="col">Recipient</th>
                <th scope="col">Feedback</th>
                <th scope="col">Date</th>
                {/* <th scope="col">Ed</th> */}
              </tr>
            </thead>

            {/* {category?.map((data) => {})} */}
            <tbody>
              {loading ? (
                <div className="text-center mt-5 pt-5">
                  <Spinner
                    animation="border"
                    className="text-center"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <>
                  {err ? (
                    <>
                      <h5 className="text-center p-4">{err}</h5>
                    </>
                  ) : (
                    <>
                      {visit?.map((data) => {
                        return (
                          <tr>
                            <th scope="row">*</th>
                            <td>{data?.nameofschool}</td>
                            <td>{data?.address}</td>
                            <td>{data?.number}</td>
                            <td>{data?.recipient}</td>
                            <td>{data?.feedback?.map((data)=> (<>{data}</>))}</td>
                            <td>{data?.date}</td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                </>
              )}
            </tbody>
              </VisitedTable>
            </div>  
            <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="">
              <SuscribedText>Recent Registered</SuscribedText>
              <button className="btn btn-dark btn-sm" onClick={()=> navigate("/registered-schools/" + id)}>View All</button>
            </div>
            <table className="table table-striped table-responsive">
            <thead>
              <tr>
                <th scope="col">S/N</th>
                <th scope="col">Name of schools</th>
                <th scope="col">Location Address</th>
                <th scope="col">Date</th>
                <th scope="col">Phone</th>
              </tr>
            </thead>

            {/* {category?.map((data) => {})} */}
            <tbody>
              {loading ? (
                <div className="text-center mt-5 pt-5">
                  <Spinner
                    animation="border"
                    className="text-center"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <>
                  {err ? (
                    <>
                      <h5 className="text-center p-4">{err}</h5>
                    </>
                  ) : (
                    <>
                      {registered?.map((data) => {
                        console.log(data)
                        return (
                          <tr>
                            <th scope="row">*</th>
                            <td>{data?.nameofschool}</td>
                            <td>{data?.visited?.address}</td>
                            <td>{data?.date}</td>
                            <td>{data?.number}</td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                </>
              )}
            </tbody>
              </table>
            </div>
          </div>
          <div className="pt-5">
            <div className="col-lg-12">
            <div className="">
              <SuscribedText>Recent Suscribed</SuscribedText>
              <button className="btn btn-dark btn-sm" onClick={()=> navigate("/suscribed-schools/62f3261133b2f8ce08597ee8")}>View All</button>
            </div>
            <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">S/N</th>
                <th scope="col">NOB</th>
                <th scope="col">School Status</th>
                <th scope="col">Activation / Registeration fee</th>
                <th scope="col">Suscription Cost/learners</th>
                <th scope="col">Total Payable</th>
                <th scope="col">Amount Paid</th>
                <th scope="col">Outstanding</th>
                <th scope="col">Payment Description</th>
                <th scope="col">Session</th>
                <th scope="col">Package</th>
                <th scope="col">Mode of Payment</th>
                <th scope="col">Payment Date</th>
                <th scope="col">Remark</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <div className="text-center mt-5 pt-5">
                  <Spinner
                    animation="border"
                    className="text-center"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <>
                  {err ? (
                    <>
                      <h5 className="text-center p-4">{err}</h5>
                    </>
                  ) : (
                    <>
                      {suscribed?.map((data) => {
                        return (
                          <>
                            <tr>
                              <th scope="row">*</th>
                              <td>{data?.nameofschool}</td>
                              <td>{data?.schoolstatus}</td>
                              <td>{data?.activationfee}</td>
                              <td>{data?.subscriptioncost}</td>
                              <td>{data?.totalpayable}</td>
                              <td>{data?.amountpaid}</td>
                              <td>
                                {data?.subscriptioncost - data?.amountpaid}
                              </td>
                              <td>{data?.paymentdescription}</td>
                              <td>{data?.session}</td>
                              <td>{data?.package}</td>
                              <td>{data?.modeofpayment}</td>
                              <td>{data?.paymentdate}</td>
                              <td>{data?.remark}</td>
                            </tr>
                          </>
                        );
                      })}
                    </>
                  )}
                </>
              )}
            </tbody>
              </table>
            </div>

            <div className="">
            <div className="">
              <SuscribedText>Recent Debtors</SuscribedText>
              <button className="btn btn-warning btn-sm" onClick={()=> alert("on construction")}>View All</button>
            </div>
            <VisitedTable className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">First</th>
                    <th scope="col">Last</th>
                    <th scope="col">Handle</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td colspan="2">Larry the Bird</td>
                    <td>@twitter</td>
                  </tr>
                </tbody>
              </VisitedTable>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-6">
              <Link to={"/visit-schools/" + id} className="btn btn-primary">
                Visit To School
              </Link>
            </div>
            <div className="col-md-6">
              <Link
                to={"/suscribed-schools/" + id}
                className="btn btn-outline-primary"
              >
                Suscribed Schools
              </Link>
            </div>
          </div> */}
          {/* <button className="btn btn-primary"></button> */}
        </section>
      </main>
      {/* <!-- End #main --> */}
    </>
  );
}


const SuscribedText = styled.h2`
font-size: 14px;
font-weight: 500;
padding: 0;
line-height: 1px;
margin: 0 0 20px 0;
letter-spacing: 2px;
text-transform: uppercase;
color: #aaaaaa;
font-family: "Poppins", sans-serif;


 ::after{
  content: "";
  width: 91px;
  height: 1px;
  display: inline-block;
  background: blue;
  margin: 4px 10px;
 }
`

const VisitedTable = styled.table`
  // background: repeating-linear-gradient(45deg, black, transparent 100px);
  // color: #fff !important;
  font-weight: bold;
  

  // td{
  //   color: #fff !important;
  // }

  // th{
  //   color: #fff !important;
  // }
`

export default ProductsLinking;
