import React, { useState, useEffect } from "react";
import { handleMoving } from "../handles";
import API_URL from "../../../config";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import ExcelExport from "react-html-table-to-excel";
function Outflow() {
  const navigate = useNavigate()
  const [outflow, setOutflow] = useState(null);
  const [loading, setLoading] = useState(false);
  const url = `${API_URL}/services/outflow/all`;
  useEffect(() => {
    setLoading(true);
    axios.get(url).then(({ data }) => {
      setOutflow(data?.payload);
      setLoading(false);
      // console.log(data);
    });
  }, [url]);
  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Services Outflow</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Home</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">Outflow</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile p-5 table-responsive">
          <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Go back
          </button>
          <Link to={"/create-outflow"} className="btn btn-primary">
            create outflow
          </Link>
          <table class="table table-responsive" id="emp-table">
            <thead>
              <tr>
                <th scope="col">S/N</th>
                <th scope="col">Description</th>
                <th scope="col">Date</th>
                <th scope="col">Amount</th>
                <th scope="col">Amount Paid</th>
              </tr>
            </thead>
            {loading ? (
              <div className="text-center mt-5 pt-5">
                <Spinner
                  animation="border"
                  className="text-center"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                <tbody>
                  {outflow?.length === 0 ? (
                    <>
                      <h5 className="text-center p-4">
                        No Outflow uploaded yet
                      </h5>
                    </>
                  ) : (
                    <>
                      {outflow?.map((data) => {
                        return (
                          <tr>
                            <th scope="row">*</th>
                            <td>{data?.description}</td>
                            {/* <td>{data?.schoolstatus}</td> */}
                            <td>{data?.date}</td>
                            <td>{data?.amount}</td>
                            <td>{data?.totalamount}</td>
                            <td>
                              <Link
                                to={"/update-outflow/" + data?.id}
                                className="btn btn-primary"
                              >
                                Edit
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={"/view-single-outflow/" + data?.id}
                                className="btn btn-primary"
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        );
                      })}

                      <tr className="bg-dark text-white">
                        <th scope="row" colspan="5" style={{ width: "100%" }}>
                          Grand Total
                        </th>
                        {/* <td rowspan="2"></td> */}
                        <td colspan="5">
                          {"\u20A6"}
                          {outflow
                            ?.reduce((prev, cur) => {
                              // console.log(prev + cur?.totalcost);
                              return prev + cur?.totalamount;
                            }, 0)
                            .toLocaleString()}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </>
            )}
          </table>
          {outflow?.length === 0 ? (
            <></>
          ) : (
            <>
              {/* <div className="row">
                <div className="col-md-6">
                  <h3 className="offset-md-8 mt-4">Total:</h3>
                </div>
                <div className="col-md-6">
                  <h3 className="offset-md-8 mt-4">
                    {"\u20A6"}
                    {outflow
                      ?.reduce((prev, cur) => {
                        // console.log(prev + cur?.totalcost);
                        return prev + cur?.totalamount;
                      }, 0)
                      .toLocaleString()}
                  </h3>
                </div>
              </div> */}
              <ExcelExport
                className="btn btn-info"
                table="emp-table"
                sheet="Sheet"
                buttonText="export to excel"
                filename="total-outflow"
              />
            </>
          )}
        </section>
      </main>
      {/* <!-- End #main --> */}
    </>
  );
}

export default Outflow;
