import React, { useState, useEffect } from "react";
import { handleMoving } from "../handles";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import API_URL from "../../../config";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
// import ReactExport from "react-export-excel";
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import ExcelExport from "react-html-table-to-excel";

// import "../../../App.css"
// import "./style.css"
function SingleGross() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [single, setSingle] = useState(null);
  const [single2, setSingle2] = useState(null);
  const [message, setMessage] = useState(null);
  const [err, setErr] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const url = `${API_URL}/payroll/gross/single/${id}`;
  useEffect(() => {
    setLoading(true);
    axios.get(url).then(({ data }) => {
      setSingle(data?.payload[0]);
      setLoading(false);
      console.log(data);
    });
  }, [url]);

  const url2 = `${API_URL}/payroll/gross/single-updated/${id}`;
  useEffect(() => {
    setLoading(true);
    axios.get(url2).then(({ data }) => {
      setSingle2(data?.payload);
      setLoading(false);
      // console.log(data)
    });
  }, [url2]);

  console.log(single);
  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Single Staff Gross</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">Staff Gross</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile p-5 table-responsive">
          <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Go back
          </button>
          {/* <h2>Services Name: {single?.services?.name}</h2> */}
          <hr />
          <h2>Name of Employee: {single?.nameofemployee}</h2>
          <hr />
          <h2>Employee Serial Number: {single?.serialnumber}</h2>
          <hr />
          <h2>Gross Salary : {single?.grosssalary?.toLocaleString()}</h2>
          <hr />
          <h2>Basic Salary: {single?.basicsalary?.toLocaleString()}</h2>
          <hr />
          <h2>Recharge Card : {single?.rechargecard?.toLocaleString()}</h2>
          <hr />
          <h2>Bonus : {single?.bonus?.toLocaleString()}</h2>
          <hr />
          <h2>
            Last Updated:{" "}
            {single?.updatedAt?.replace(/T/, " ")?.replace(/\..+/, "")}
          </h2>
          <hr />
          <Link to={"/gross/" + single?._id} className="btn btn-primary">
            Edit
          </Link>

          {single2?.map((data) => {
            return (
              <>
                <h2>Name of Employee: {data?.nameofemployee}</h2>
                <hr />
                <h2>Employee Serial Number: {data?.serialnumber}</h2>
                <hr />
                <h2>Gross Salary : {data?.grosssalary?.toLocaleString()}</h2>
                <hr />
                <h2>Basic Salary: {data?.basicsalary?.toLocaleString()}</h2>
                <hr />
                <h2>Recharge Card : {data?.rechargecard?.toLocaleString()}</h2>
                <hr />
                <h2>Bonus : {data?.bonus?.toLocaleString()}</h2>
                <hr />
                <h2>
                  Last Updated:{" "}
                  {data?.updatedAt?.replace(/T/, " ")?.replace(/\..+/, "")}
                </h2>
                <hr />
              </>
            );
          })}
        </section>
      </main>
    </>
  );
}

export default SingleGross;
