import React, { useState, useEffect } from "react";
// import { handleMoving } from "../handles";
import Header from "../../../Header";
import Sidebar from "../../../Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import API_URL from "../../../../../config/index";
import { Button, Form, Spinner } from "react-bootstrap";
import { getToken } from "../../../../../utils/Common";
import axios from "axios";

// import "../../../App.css"
// import "./style.css"
function CreateVisitJosrac() {
  const navigate = useNavigate();
  const [select, setSelect] = useState("");
  const [select2, setSelect2] = useState("");
  const { id } = useParams();
  const [message, setMessage] = useState(null);
  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState({
    nameofschool: "",
    address: "",
    number: "",
    recipient: "",
    date: "",
    feedback: "",
  });

  const url = `${API_URL}/product/visited/create/${id}`;

  const token = getToken();
  const axiosCreate = axios.create({
    headers: {
      authorization: "Bearer " + token,
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setShow(false);
    axios
      .post(url, {
        nameofschool: data?.nameofschool,
        address: data?.address,
        number: data?.number,
        recipient: select === "Other" ? select2 : select,
        feedback: data?.feedback,
        date: data?.date,
      })
      .then(({ data }) => {
        setMessage(data?.message);
        setLoading(false);
      })
      .catch((err) => {
        setShow(true)
        setErr(err?.response?.data?.message);
        setLoading(false);
        setShow(true);
      });
  };

  const handle = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
    console.log(newData);
  };

  const handleSelect = (e) => {
    setSelect(e.target.value);
  };
  const handleSelect2 = (e) => {
    setSelect2(e.target.value);
  };
  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Create Visit</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">Create Visit School</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profil table-responsive">
        <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Go back
          </button>
          {loading ? (
            <div className="text-center mt-5 pt-5">
              <Spinner animation="border" className="text-center" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              {err && show ? (
                <div
                  class="alert alert-danger text-center container w-50"
                  role="alert"
                >
                  <strong>{err}</strong>
                </div>
              ) : (
                <div style={{ display: "none" }}>{err}</div>
              )}

              {message ? (
                <div class="alert alert-success text-center " role="alert">
                  <strong>{message}</strong>
                  <p style={{ display: "none" }}>
                    {setTimeout(() => navigate(`/visit-schools/${id}`), 3000)}
                  </p>
                </div>
              ) : (
                <div></div>
              )}
              <h2 className="display-5 text-center m-5">
                Create School Visit Josrac
              </h2>
              <Form
                className="w-50 mx-auto"
                onSubmit={(e) => handleSubmit(e)}
                //   enctype="multipart/form-data"
                method="post"
                style={{ width: "50%", margin: "auto" }}
              >
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name of School</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="enter name of school"
                    className="w-100"
                    value={data?.nameofschool}
                    onChange={(e) => handle(e)}
                    name="nameofschool"
                    required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Address/Location</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="enter location or school adress"
                    className="w-100"
                    value={data?.address}
                    onChange={(e) => handle(e)}
                    name="address"
                    required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Mobile No</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="enter tel no"
                    className="w-100"
                    value={data?.number}
                    onChange={(e) => handle(e)}
                    name="number"
                    required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Feedback</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="enter feedback"
                    className="w-100"
                    value={data?.feedback}
                    onChange={(e) => handle(e)}
                    name="feedback"
                    required
                  />
                </Form.Group>
                <label for="Recipient">Recipient</label>
                <select
                    name="recipient"
                    className="form-control"
                    value={select}
                    onChange={(e) => handleSelect(e)}
                    disabled={select === "Other"}
                  >
                    <option selected>
                      Select Recipient
                    </option>
                    <option value="Director">
                      Director
                    </option>
                    <option value="Propietor">
                      Propietor
                    </option>
                    <option value="Admin">
                      Admin
                    </option>
                    <option value="Teacher">
                      Teacher
                    </option>
                    <option value="Other">
                      Other
                    </option>
                  </select><br />
                  {select === "Other" && (
                    <>
                    <Form.Control
                      type="text"
                      placeholder="please fill recipient field"
                      className="w-50"
                      value={select2}
                      onChange={(e) => handleSelect2(e)}
                      name="recipient"
                      required
                    />
                    <Button variant="dark" onClick={()=> setSelect("")}>Cancel</Button><br ></br>
                    </>
                  )}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="enter date"
                    className="w-100"
                    value={data?.date}
                    onChange={(e) => handle(e)}
                    name="date"
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Create School Visit
                </Button>
                {/* <Button variant="outline-warning">Go Back</Button> */}
              </Form>
            </>
          )}
        </section>
      </main>
      {/* <!-- End #main --> */}
    </>
  );
}

export default CreateVisitJosrac;
