import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import API_URL from "../../config/index";
import { Button, Form, Spinner } from "react-bootstrap";
import { getAdmin, getToken } from "../../utils/Common";

// console.log(getAdmin());
const adminDetails = getAdmin();
function EditProfile() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [image, setImage] = useState("");
  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    country: "",
    about: "",
    phone: "",
    address: "",
    twitter: "",
    facebook: "",
    position: "",
    linkendin: "",
    instagram: "",
  });

  const url2 = `${API_URL}/admin/single`;

  const url = `${API_URL}/admin/update`;

  const token = getToken();
  const axiosCreate = axios.create({
    headers: {
      authorization: "Bearer " + token,
    },
  });
  useEffect(() => {
    setLoading(true);
    axios.post(url2, { id: adminDetails?._id }).then(({ data }) => {
      setData(data?.payload[0]);
      setImage(data?.payload[0]);
      console.log(data?.payload[0]);
      setLoading(false);
      // console.log(data);
    });
  }, [url2]);

  const handleSubmit = (e) => {
    // const formData = new FormData();
    // console.log(image)
    // formData.append("title", data?.title);
    e.preventDefault();
    setShow(false);
    setLoading(true);

    const form = new FormData();
    form.append("image", image);
    form.append("username", data?.username);
    form.append("firstname", data?.firstname);
    form.append("lastname", data?.lastname);
    form.append("email", data?.email);
    form.append("country", data?.country);
    form.append("about", data?.about);
    form.append("phone", data?.phone);
    form.append("address", data?.address);
    form.append("position", data?.position);
    form.append("linkendin", data?.linkendin);
    form.append("twitter", data?.twitter);
    form.append("facebook", data?.facebook);
    form.append("instagram", data?.instagram);
    form.append("id", adminDetails?._id);
    axios
      .put(url, form)
      .then(({ data }) => {
        setMessage(data?.message);
        setLoading(false);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        // console.log(err?.response?.data?.message);
        setLoading(false);
        setShow(true);
      });
  };

  const handleImage = (e) => {
    setImage(e.target.files[0]);
  };
  const handle = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
    console.log(newData);
  };
  return (
    <div className="tab-pane fade profile-edit pt-3" id="profile-edit">
      {/* <!-- Profile Edit Form --> */}
      {loading ? (
        <div className="text-center mt-5 pt-5">
          <Spinner animation="border" className="text-center" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          {err && show ? (
            <div
              class="alert alert-danger text-center container w-50"
              role="alert"
            >
              <strong>{err}</strong>
            </div>
          ) : (
            <div style={{ display: "none" }}>{err}</div>
          )}

          {message ? (
            <div class="alert alert-success text-center " role="alert">
              <strong>{message}</strong>
              <p style={{ display: "none" }}>
                {setTimeout(() => navigate("/profile"), 3000)}
              </p>
            </div>
          ) : (
            <div></div>
          )}
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="row mb-3">
              <label
                for="profileImage"
                className="col-md-4 col-lg-3 col-form-label"
              >
                Profile Image
              </label>
              <div className="col-md-8 col-lg-9">
                <img src={data?.image} alt="No image uploaded yet" />
                <div className="pt-2">
                  <input
                    type="file"
                    name="image"
                    onChange={(e) => handleImage(e)}
                    className="btn btn-primary btn-sm"
                  />
                  {/* <i className="bi bi-upload"></i> */}
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <label
                for="fullName"
                className="col-md-4 col-lg-3 col-form-label"
              >
                Firstname
              </label>
              <div className="col-md-8 col-lg-9">
                <input
                  name="firstname"
                  type="text"
                  className="form-control"
                  id="fullName"
                  value={data?.firstname}
                  onChange={(e) => handle(e)}
                />
              </div>
            </div>

            <div className="row mb-3">
              <label for="about" className="col-md-4 col-lg-3 col-form-label">
                About
              </label>
              <div className="col-md-8 col-lg-9">
                <textarea
                  name="about"
                  className="form-control"
                  id="about"
                  style={{ height: "100px" }}
                  onChange={(e) => handle(e)}
                  value={data?.about}
                >
                  {data?.about}
                </textarea>
              </div>
            </div>

            <div className="row mb-3">
              <label for="Job" className="col-md-4 col-lg-3 col-form-label">
                Position
              </label>
              <div className="col-md-8 col-lg-9">
                <input
                  name="position"
                  type="text"
                  className="form-control"
                  id="Job"
                  onChange={(e) => handle(e)}
                  value={data?.position}
                />
              </div>
            </div>

            <div className="row mb-3">
              <label for="Country" className="col-md-4 col-lg-3 col-form-label">
                Country
              </label>
              <div className="col-md-8 col-lg-9">
                <input
                  name="country"
                  type="text"
                  className="form-control"
                  id="Country"
                  value={data?.country}
                  onChange={(e) => handle(e)}
                />
              </div>
            </div>

            <div className="row mb-3">
              <label for="Address" className="col-md-4 col-lg-3 col-form-label">
                Address
              </label>
              <div className="col-md-8 col-lg-9">
                <input
                  name="address"
                  type="text"
                  className="form-control"
                  id="Address"
                  onChange={(e) => handle(e)}
                  value={data?.address}
                />
              </div>
            </div>

            <div className="row mb-3">
              <label for="Phone" className="col-md-4 col-lg-3 col-form-label">
                Phone
              </label>
              <div className="col-md-8 col-lg-9">
                <input
                  name="phone"
                  type="text"
                  className="form-control"
                  id="Phone"
                  onChange={(e) => handle(e)}
                  value={data?.phone}
                />
              </div>
            </div>

            <div className="row mb-3">
              <label for="Email" className="col-md-4 col-lg-3 col-form-label">
                Email
              </label>
              <div className="col-md-8 col-lg-9">
                <input
                  name="email"
                  type="email"
                  className="form-control"
                  id="Email"
                  onChange={(e) => handle(e)}
                  value={data?.email}
                />
              </div>
            </div>

            <div className="row mb-3">
              <label for="Twitter" className="col-md-4 col-lg-3 col-form-label">
                Twitter Profile
              </label>
              <div className="col-md-8 col-lg-9">
                <input
                  name="twitter"
                  type="text"
                  className="form-control"
                  id="Twitter"
                  onChange={(e) => handle(e)}
                  value={data?.twitter}
                />
              </div>
            </div>

            <div className="row mb-3">
              <label
                for="Facebook"
                className="col-md-4 col-lg-3 col-form-label"
              >
                Facebook Profile
              </label>
              <div className="col-md-8 col-lg-9">
                <input
                  name="facebook"
                  type="text"
                  className="form-control"
                  id="Facebook"
                  onChange={(e) => handle(e)}
                  value={data?.facebook}
                />
              </div>
            </div>

            <div className="row mb-3">
              <label
                for="Instagram"
                className="col-md-4 col-lg-3 col-form-label"
              >
                Instagram Profile
              </label>
              <div className="col-md-8 col-lg-9">
                <input
                  name="instagram"
                  type="text"
                  className="form-control"
                  id="Instagram"
                  onChange={(e) => handle(e)}
                  value={data?.instagram}
                />
              </div>
            </div>

            <div className="row mb-3">
              <label
                for="Linkedin"
                className="col-md-4 col-lg-3 col-form-label"
              >
                Linkedin Profile
              </label>
              <div className="col-md-8 col-lg-9">
                <input
                  name="linkendin"
                  type="text"
                  className="form-control"
                  id="Linkedin"
                  onChange={(e) => handle(e)}
                  value={data?.linkendin}
                />
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-primary">
                Save Changes
              </button>
            </div>
          </form>
        </>
      )}
      {/* <!-- End Profile Edit Form --> */}
    </div>
  );
}

export default EditProfile;
