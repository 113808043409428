import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import API_URL from "../../config";
import { getAdmin } from "../../utils/Common";

console.log(getAdmin());
const adminDetails = getAdmin();
function ProfileOverview() {
  const { id } = useParams();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState();
  const url = `${API_URL}/admin/single`;
  useEffect(() => {
    setLoading(true);
    axios.post(url, { id: adminDetails?._id }).then(({ data }) => {
      setUser(data?.payload[0]);
      setLoading(false);
      console.log(data);
    });
  }, [url]);

  //   console.log(user)
  return (
    <div
      className="tab-pane fade show active profile-overview"
      id="profile-overview"
    >
      <h5 className="card-title">About</h5>
      <p className="small fst-italic">
        {user?.about
          ? `${user?.about}`
          : "No about details has been uploaded yet"}
      </p>

      <h5 className="card-title">Profile Details</h5>

      <div className="row">
        <div className="col-lg-3 col-md-4 label ">Username</div>
        <div className="col-lg-9 col-md-8">{user?.username}</div>
      </div>

      <div className="row">
        <div className="col-lg-3 col-md-4 label">Country</div>
        <div className="col-lg-9 col-md-8">{user?.country}</div>
      </div>

      <div className="row">
        <div className="col-lg-3 col-md-4 label">Phone</div>
        <div className="col-lg-9 col-md-8">
          {user?.phone
            ? `${user?.phone}`
            : "Phone number has not been uploaded"}
        </div>
      </div>

      <div className="row">
        <div className="col-lg-3 col-md-4 label">Email</div>
        <div className="col-lg-9 col-md-8">{user?.email}</div>
      </div>
    </div>
  );
}

export default ProfileOverview;
