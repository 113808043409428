import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { handleMoving } from "../handles";
import Header from "../Header";
import Sidebar from "../Sidebar";
import API_URL from "../../../config";
// import "../../../App.css"
import { Button, Form, Spinner } from "react-bootstrap";

import { getUserToken, getToken } from "../../../utils/Common";
import axios from "axios";
// import "./style.css"
function UpdateInflowServices() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [message, setMessage] = useState(null);
  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState({
    servicetype: "",
    description: "",
    totalcost: "",
    amountpaid: "",
    outstanding: "",
    projectduration: "",
    status: "",
  });

  const url = `${API_URL}/services/inflow-services/update/${id}`;

  const token = getToken();
  const axiosCreate = axios.create({
    headers: {
      authorization: "Bearer " + token,
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setShow(false);
    axios
      .put(url, {
        servicetype: data?.servicetype,
        description: data?.description,
        totalcost: data?.totalcost,
        amountpaid: data?.amountpaid,
        projectduration: data?.projectduration,
        status: data?.status,
        outstanding: data?.outstanding,
      })
      .then(({ data }) => {
        setMessage(data?.message);
        setLoading(false);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setLoading(false);
        setShow(true);
      });
  };
  const url3 = `${API_URL}/services/inflow-services/${id}`;
  useEffect(() => {
    setLoading(true);
    axios.get(url3).then(({ data }) => {
      setData(data?.payload[0]);
      setLoading(false);
    });
  }, [url3]);

  const handle = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
    console.log(newData);
  };
  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Inflow Services</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">Update</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}
        <section className="section profil table-responsive">
          <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Go back
          </button>
          {loading ? (
            <div className="text-center mt-5 pt-5">
              <Spinner animation="border" className="text-center" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              {err && show ? (
                <div
                  class="alert alert-danger text-center container w-50"
                  role="alert"
                >
                  <strong>{err}</strong>
                </div>
              ) : (
                <div style={{ display: "none" }}>{err}</div>
              )}

              {message ? (
                <div class="alert alert-success text-center " role="alert">
                  <strong>{message}</strong>
                  <p style={{ display: "none" }}>
                    {setTimeout(() => navigate(`/inflow-services/${id}`), 3000)}
                  </p>
                </div>
              ) : (
                <div></div>
              )}
              <h2 className="display-5 text-center m-5">
                Update Inflow Services
              </h2>
              <Form
                className="w-50 mx-auto"
                onSubmit={(e) => handleSubmit(e)}
                //   enctype="multipart/form-data"
                method="post"
                style={{ width: "50%", margin: "auto" }}
              >
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Service Type</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="enter service type"
                    className="w-100"
                    value={data?.servicetype}
                    onChange={(e) => handle(e)}
                    name="servicetype"
                    // required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Description Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="enter description no"
                    className="w-100"
                    value={data?.description}
                    onChange={(e) => handle(e)}
                    name="description"
                    // required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Total Cost</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="enter subscription cost"
                    className="w-100"
                    value={data?.totalcost}
                    onChange={(e) => handle(e)}
                    name="totalcost"
                    // required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Amount Paid</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="enter Total Payable"
                    className="w-100"
                    value={data?.amountpaid}
                    onChange={(e) => handle(e)}
                    name="amountpaid"
                    // required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Outstanding</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="enter outstanding"
                    className="w-100"
                    value={data?.outstanding}
                    onChange={(e) => handle(e)}
                    name="outstanding"
                    // required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Project Duration</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="enter Payment description"
                    className="w-100"
                    value={data?.projectduration}
                    onChange={(e) => handle(e)}
                    name="projectduration"
                    // required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="status"
                    className="w-100"
                    value={data?.status}
                    onChange={(e) => handle(e)}
                    name="status"
                    // required
                  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Update Inflow Services
                </Button>
                {/* <Button variant="outline-warning">Go Back</Button> */}
              </Form>
            </>
          )}
        </section>
      </main>
      {/* <!-- End #main --> */}
    </>
  );
}

export default UpdateInflowServices;
