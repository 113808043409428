import React from "react";
import axios from "axios";
import { removeUserSession } from "../utils/Common";
import { useNavigate } from "react-router-dom";

function ModalDelete({
  title,
  writeup,
  id,
  url,
  setMessage,
  setLoading,
  setErr,
  logout,
}) {
  const navigate = useNavigate();
  const handleDelete = (id, e) => {
    // const deleteurl = API_URL + "/admin/delete-order/" + id;
    console.log(id)
    e.preventDefault();
    setLoading(true);
    axios
      .delete(`${url}${id}`)
      .then(({ data }) => {
        console.log(data);
        setLoading(false);
        setMessage(data?.message);
      })
      .catch((err) => {
        setErr(err?.response?.data?.data);
        setLoading(false);
      });

    if (logout === true) {
      setTimeout(() => {
        removeUserSession(), navigate("/auth");
      }, 4000);
      // setTimeout(() => setMessage("Logout successfull"), 2000);
    }
  };

  // console.log(id)

  return (
    <>
      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content bg-light">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                {title}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">{writeup}</div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={(e) => handleDelete(id, e)}
              >
                Yes Understood
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalDelete;
