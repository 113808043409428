import React, { useState, useEffect } from "react";
import { handleMoving } from "../handles";
import API_URL from "../../../config";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Button, Spinner } from "react-bootstrap";
import axios from "axios";
function InflowHome() {
  const navigate = useNavigate();
  const [category, setCategory] = useState(null);
  const [services, setServices] = useState(null);
  const [message, setMessage] = useState(null);
  const [err, setErr] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const url = `${API_URL}/product`;
  useEffect(() => {
    setLoading(true);
    axios.get(url).then(({ data }) => {
      setCategory(data?.payload);
      setLoading(false);
      // console.log(data)
    });
  }, [url]);
  const url2 = `${API_URL}/services`;
  useEffect(() => {
    setLoading(true);
    axios.get(url2).then(({ data }) => {
      setServices(data?.payload);
      setLoading(false);
      // console.log(data)
    });
  }, [url2]);

  //   function handleProductNavigate(data, e) {
  //     e.preventDefault();
  //     navigate(`/${data?.id}`);
  //   }

  const handleProductNavigate = (data) => {
    navigate(`/inflow-product/${data}`);
  };
  const handleServicesNavigate = (data) => {
    navigate(`/inflow-services/${data}`);
  };
  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Services Inflow Home</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Home</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">Inflow Home</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile p-5 table-responsive">
        <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Go back
          </button>
          {loading ? (
            <div className="text-center mt-5 pt-5">
              <Spinner animation="border" className="text-center" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              <h2 className="text-center text-primary">__Services</h2>
              <div className="row">
                {services?.map((data) => {
                  return (
                    <>
                      <div className="col-md-3">
                        <Button
                          variant="primary"
                          //   onClick={handleServicesNavigate(data?.id)}
                          onClick={() => handleServicesNavigate(data?._id)}
                        >
                          {data?.name}
                        </Button>
                      </div>
                    </>
                  );
                })}
              </div>

              <h2 className="text-center text-primary">__Product</h2>
              {/* <Button variant="primary">Josrac</Button> */}
              {category?.map((data) => {
                console.log(data);
                return (
                  <>
                    <div className="col-md-3">
                      <Button
                        variant="primary"
                        onClick={() => handleProductNavigate(data?._id)}
                      >
                        {data?.name}
                      </Button>
                    </div>
                  </>
                );
              })}
            </>
          )}
        </section>
      </main>
      {/* <!-- End #main --> */}
    </>
  );
}

export default InflowHome;
