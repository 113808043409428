import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import API_URL from "../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getUserData, removeUserSession } from "../../utils/Common";
import { Spinner } from "react-bootstrap";
function Sidebar() {
  const navigate = useNavigate();
  const [category, setCategory] = useState(null);
  const [services, setServices] = useState(null);
  const [message, setMessage] = useState(null);
  const [err, setErr] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const url = `${API_URL}/product`;
  useEffect(() => {
    setLoading(true);
    axios.get(url).then(({ data }) => {
      setCategory(data?.payload);
      setLoading(false);
      // console.log(data)
    });
  }, [url]);
  const url2 = `${API_URL}/services`;
  useEffect(() => {
    setLoading(true);
    axios.get(url2).then(({ data }) => {
      setServices(data?.payload);
      setLoading(false);
      // console.log(data)
    });
  }, [url2]);

  const handleLogOut = () => {
    setLoading(true)
    // removeUserSession();
    setTimeout(() => removeUserSession(), navigate("/auth"), 3000)
    // navigate("/auth");
  };
  // console.log(category);



  return (
    <>
      {/* <!-- ======= Sidebar ======= --> */}
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <a className="nav-link " href="/">
              <i className="bi bi-grid"></i>
              <span>Dashboard</span>
            </a>
          </li>
          {/* <!-- End Dashboard Nav --> */}

          <li className="nav-item">
            <a
              className="nav-link collapsed"
              data-bs-target="#components-nav"
              data-bs-toggle="collapse"
              href="#"
            >
              <i className="bi bi-menu-button-wide"></i>
              <span>Product</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </a>
            {loading ? (
              <div className="text-center">
                <Spinner
                  animation="border"
                  className="text-center"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                <ul
                  id="components-nav"
                  className="nav-content collapse "
                  data-bs-parent="#sidebar-nav"
                >
                  <li>
                    <a href="/create-product">
                      <i className="bi bi-circle"></i>
                      <span>Create Product</span>
                    </a>
                  </li>
                  <li>
                    <a href="/products">
                      <i className="bi bi-circle"></i>
                      <span>All Product</span>
                    </a>
                  </li>
                  {category?.map((data) => {
                    return (
                      <>
                        <li>
                          <Link to={"/product/" + data?.id}>
                            <i className="bi bi-circle"></i>
                            <span className="text-uppercase">{data?.name}</span>
                          </Link>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </>
            )}
          </li>
          {/* <!-- End Components Nav --> */}

          {/* <!-- End Dashboard Nav --> */}

          <li className="nav-item">
            <a
              className="nav-link collapsed"
              data-bs-target="#services"
              data-bs-toggle="collapse"
              href="#"
            >
              <i className="bi bi-menu-button-wide"></i>
              <span>Services</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </a>
            {loading ? (
              <div className="text-center">
                <Spinner
                  animation="border"
                  className="text-center"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                <ul
                  id="services"
                  className="nav-content collapse "
                  data-bs-parent="#sidebar-nav"
                >
                  <li>
                    <a href="/create-services">
                      <i className="bi bi-circle"></i>
                      <span>Create Service</span>
                    </a>
                  </li>
                  <li>
                    <a href="/services">
                      <i className="bi bi-circle"></i>
                      <span>All Services</span>
                    </a>
                  </li>
                  {services?.map((data) => {
                    return (
                      <>
                        <li>
                          <Link to={"/services/" + data?.id}>
                            <i className="bi bi-circle"></i>
                            <span className="text-uppercase">{data?.name}</span>
                          </Link>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </>
            )}
          </li>
          {/* <!-- End Components Nav --> */}

          <li className="nav-item">
            <a
              className="nav-link collapsed"
              data-bs-target="#flows"
              data-bs-toggle="collapse"
              href="#"
            >
              <i className="bi bi-menu-button-wide"></i>
              <span>FLOWS</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul
              id="flows"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <a href="/inflow">
                  <i className="bi bi-circle"></i>
                  <span>TOTAL INFLOW</span>
                </a>
              </li>
              <li>
                <a href="/outflow">
                  <i className="bi bi-circle"></i>
                  <span>TOTAL OUTFLOW</span>
                </a>
              </li>
              <li>
                <a href="/balance">
                  <i className="bi bi-circle"></i>
                  <span>BALANCE</span>
                </a>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              data-bs-target="#payroll"
              data-bs-toggle="collapse"
              href="#"
            >
              <i className="bi bi-menu-button-wide"></i>
              <span>Payroll</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul
              id="payroll"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <a href="/gross">
                  <i className="bi bi-circle"></i>
                  <span>Total Gross</span>
                </a>
              </li>
              <li>
                <a href="/deduction">
                  <i className="bi bi-circle"></i>
                  <span>Total Deduction</span>
                </a>
              </li>
            </ul>
          </li>
          {/* <!-- End Components Nav --> */}

          <li className="nav-item">
            <a className="nav-link collapsed" href="/profile">
              <i className="bi bi-person"></i>
              <span>Profile</span>
            </a>
          </li>
          {/* <!-- End Profile Page Nav --> */}

          <li className="nav-item">
            <a className="nav-link collapsed" href="#">
              <i className="bi bi-question-circle"></i>
              <span>F.A.Q</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link collapsed" onClick={handleLogOut}>
              <i class="bi bi-box-arrow-left"></i>
              <span onClick={handleLogOut}>LOGOUT</span>
            </a>
          </li>
        </ul>
      </aside>
    </>
  );
}

export default Sidebar;
