import React, { useState, useEffect } from "react";
import { handleMoving } from "../handles";
import API_URL from "../../../config";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import ExcelExport from "react-html-table-to-excel";
function InflowProduct() {
  const navigate = useNavigate()
  const { id } = useParams();
  const [servicesinflow, setServicesInflow] = useState(null);
  const [productinflow, setProductInflow] = useState(null);
  const [message, setMessage] = useState(null);
  const [err, setErr] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const url2 = `${API_URL}/product/inflow-product/all/main/${id}`;
  useEffect(() => {
    setLoading(true);
    axios.get(url2).then(({ data }) => {
      console.log(data);
      setProductInflow(data?.payload[0]);
      setLoading(false);
      console.log(data);
    });
  }, [url2]);

  const calculateTotalProduct = productinflow?.reduce((prev, cur) => {
    // console.log(prev + cur?.totalcost);
    return prev + cur?.totalcost;
  }, 0);

  console.log(productinflow?.length);

  const naira = "\u20A6";
  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Services InflowProduct</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Home</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">InflowProduct</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile p-5 table-responsive">
        <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Go back
          </button>
          <h2 className="text-center text-primary">__Product</h2>
          <Link to={"/create-inflow-product/" + id} className="btn btn-primary">
            create Inflow Product
          </Link>
          <table class="table table-responsive" id="product">
            <thead>
              <tr>
                <th scope="col">S/N</th>
                <th scope="col">Service Type</th>
                <th scope="col">Description No</th>
                <th scope="col">Total Cost</th>
                <th scope="col">Amount Paid</th>
                <th scope="col">Outstanding</th>
                <th scope="col">Project Duration</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            {loading ? (
              <div className="text-center mt-5 pt-5">
                <Spinner
                  animation="border"
                  className="text-center"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                <tbody>
                  {productinflow?.length === undefined ? (
                    <>
                      <h5 className="text-center p-4">
                        No inflow Product uploaded yet
                      </h5>
                    </>
                  ) : (
                    <>
                      {productinflow?.map((data) => {
                        return (
                          <tr>
                            <th scope="row">*</th>
                            <td>{data?.servicetype}</td>
                            {/* <td>{data?.schoolstatus}</td> */}
                            <td>{data?.description}</td>
                            <td>{data?.totalcost}</td>
                            <td>{data?.amountpaid}</td>
                            <td>{data?.outstanding}</td>
                            <td>{data?.projectduration}</td>
                            <td>{data?.status}</td>
                            <td>
                              <Link
                                to={"/update-inflow-product/" + data?.id}
                                className="btn btn-primary"
                              >
                                Edit
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={"/view-inflow-product/" + data?.id}
                                className="btn btn-primary"
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                        <tr className="bg-dark text-white">
                        <th scope="row" colspan="5" style={{ width: "100%" }}>
                          Grand Total
                        </th>
                        {/* <td rowspan="2"></td> */}
                        <td colspan="5">
                          {naira}
                          {calculateTotalProduct?.toLocaleString()}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </>
            )}
          </table>
          {productinflow?.length === 0 ? (
            <></>
          ) : (
            <>
              <ExcelExport
                className="btn btn-info"
                table="product"
                sheet="Sheet"
                buttonText="export to excel"
                filename="InflowProduct Product"
              />
            </>
          )}
          {/* <div className="row" id="emp-table">
            <div className="col-md-6">
              <h3 className="offset-md-8 mt-4">Total:</h3>
            </div>
            <div className="col-md-6">
              <h3 className="offset-md-8 mt-4">
                {"\u20A6"}
                {calculateTotalProduct?.toLocaleString()}
              </h3>
            </div>
          </div> */}
        </section>
      </main>
      {/* <!-- End #main --> */}
    </>
  );
}

export default InflowProduct;
