import React, { useState, useEffect } from "react";
import { handleMoving } from "../handles";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Spinner } from "react-bootstrap";
import API_URL from "../../../config";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import ExcelExport from "react-html-table-to-excel";
function SuscribedSchools() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [category, setCategory] = useState(null);
  const [message, setMessage] = useState(null);
  const [err, setErr] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const url = `${API_URL}/product/suscribed/product/${id}`;
  useEffect(() => {
    setLoading(true);
    axios.get(url).then(({ data }) => {
      setCategory(data?.payload[0]);
      setLoading(false);
      // console.log(data)
    }).catch((error)=>{
      setErr(error?.response?.data?.message)
    })
  }, [url]);
  const calculateApplication = category?.reduce((prev, cur) => {
    // console.log(prev + cur?.totalcost);
    return prev + cur?.activationfee;
  }, 0);
  const calculateSubsription = category?.reduce((prev, cur) => {
    // console.log(prev + cur?.totalcost);
    return prev + cur?.subscriptioncost;
  }, 0);
  const calculatePayable = category?.reduce((prev, cur) => {
    // console.log(prev + cur?.totalcost);
    return prev + cur?.totalpayable;
  }, 0);
  const calculatePaid = category?.reduce((prev, cur) => {
    // console.log(prev + cur?.totalcost);
    return prev + cur?.amountpaid;
  }, 0);
  const calculateOutstanding = category?.reduce((prev, cur) => {
    // console.log(prev + cur?.totalcost);
    return prev + cur?.outstanding;
  }, 0);

  const naira = "\u20A6";

      // search
      const [searchField, setSearchField] = useState("");
      const [searchShow, setSearchShow] = useState(false);
    
      const filteredPersons = category?.filter((person) => {
        return (
          person?.nameofschool?.toLowerCase()?.includes(searchField?.toLowerCase()) ||
          person?.address?.toLowerCase()?.includes(searchField?.toLowerCase()) ||
          person?.package?.toLowerCase()?.includes(searchField?.toLowerCase())
        );
      });
  
      console.log(filteredPersons)
  
  
  
    const handleChange = (e) => {
      setSearchField(e.target.value);
      if (e.target.value === "") {
        setSearchShow(false);
      } else {
        setSearchShow(true);
      }
    };

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Suscribed to Schools</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">Suscribed to schools</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}
        <button className="btn btn-primary" onClick={() => navigate(-1)}>
          Go back
        </button>

        <section className="section profile p-5 table-responsive">
          <div className="pb-4">
            <Link
              to={"/all-registered/" + id}
              className="btn btn-primary"
            >
              create Suscribed schools
            </Link><br /><br />
            <input
            class="form-control me-2"
            type="search"
            placeholder="Enter school name, school address or package"
            aria-label="Search"
            onChange={handleChange}
            style={{
              display: "block",
              width: "100%",
              height: "38px",
              fontSize: "16px",
            }}
          />
          </div>
          <table class="table table-responsive" id="emp-table">
            <thead>
              <tr>
                <th scope="col">S/N</th>
                <th scope="col">NOB</th>
                <th scope="col">School Status</th>
                <th scope="col">Activation / Registeration fee</th>
                <th scope="col">Suscription Cost/learners</th>
                <th scope="col">Total Payable</th>
                <th scope="col">Amount Paid</th>
                <th scope="col">Outstanding</th>
                <th scope="col">Payment Description</th>
                <th scope="col">Session</th>
                <th scope="col">Package</th>
                <th scope="col">Mode of Payment</th>
                <th scope="col">Payment Date</th>
                <th scope="col">view</th>
                <th scope="col">Edit</th>
                <th scope="col">Update</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <div className="text-center mt-5 pt-5">
                  <Spinner
                    animation="border"
                    className="text-center"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <>
                  {err ? (
                    <>
                      <h5 className="text-center p-4">{err}</h5>
                    </>
                  ) : (
                    <>
                    {searchShow ? (
                        <>
                         {filteredPersons?.length === 0 ? (
                         <div style={{width: "400px"}}>
                          No school found with the above search <strong>{searchField}</strong>
                         </div>
                         ) : (
                            <>
                              {filteredPersons?.map((data) => {
                                return (
                                <>
                                  <tr key={data?.id}>
                                    <th scope="row">*</th>
                                    <td>{data?.nameofschool}</td>
                                    <td>{data?.schoolstatus}</td>
                                    <td>{data?.activationfee}</td>
                                    <td>{data?.subscriptioncost}</td>
                                    <td>{data?.totalpayable}</td>
                                    <td>{data?.amountpaid}</td>
                                    <td>
                                      {data?.subscriptioncost - data?.amountpaid}
                                    </td>
                                    <td>{data?.paymentdescription}</td>
                                    <td>{data?.session}</td>
                                    <td>{data?.package}</td>
                                    <td>{data?.modeofpayment}</td>
                                    <td>{data?.paymentdate}</td>
                                    <td>
                                      <Link
                                        to={"/edit-suscribed-school/" + data?.id}
                                        className="btn btn-primary"
                                      >
                                        Edit
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to={"/view-suscribed-school/" + data?.id}
                                        className="btn btn-primary"
                                      >
                                        View
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to={"/update-suscribed-school/" + data?.id}
                                        className="btn btn-primary"
                                      >
                                        Update
                                      </Link>
                                    </td>
                                  </tr>
                                </>
                        );
                      })}
                            </>
                         )}
                        </>
                      ) : (
                        <>
                        
                        {category?.map((data) => {
                          return (
                            <>
                              <tr>
                                <th scope="row">*</th>
                                <td>{data?.nameofschool}</td>
                                <td>{data?.schoolstatus}</td>
                                <td>{data?.activationfee}</td>
                                <td>{data?.subscriptioncost}</td>
                                <td>{data?.totalpayable}</td>
                                <td>{data?.amountpaid}</td>
                                <td>
                                  {data?.subscriptioncost - data?.amountpaid}
                                </td>
                                <td>{data?.paymentdescription}</td>
                                <td>{data?.session}</td>
                                <td>{data?.package}</td>
                                <td>{data?.modeofpayment}</td>
                                <td>{data?.paymentdate}</td>
                                <td>
                                  <Link
                                    to={"/edit-suscribed-school/" + data?.id}
                                    className="btn btn-primary"
                                  >
                                    Edit
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={"/view-suscribed-school/" + data?.id}
                                    className="btn btn-primary"
                                  >
                                    View
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={"/update-suscribed-school/" + data?.id}
                                    className="btn btn-primary"
                                  >
                                    Update
                                  </Link>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                        <tr className="bg-dark text-white">
                    <th scope="row" colspan="3" style={{ width: "100%" }}>
                      Grand Total
                    </th>
                    {/* <td rowspan="2"></td> */}
                    <td rowspan="">
                      {naira}
                      {calculateApplication?.toLocaleString()}
                    </td>
                    <td rowspan="">
                      {naira}
                      {calculateSubsription?.toLocaleString()}
                    </td>
                    <td rowspan="">
                      {naira}
                      {calculatePayable?.toLocaleString()}
                    </td>
                    <td rowspan="">
                      {naira}
                      {calculatePaid?.toLocaleString()}
                    </td>
                    <td colspan="9">
                      {naira}
                      {calculateOutstanding?.toLocaleString()}
                    </td>
                  </tr>
                        </>
                      )}
                    </>
                  )}
                  
                </>
              )}
            </tbody>
          </table>

          {/* <h2>
            Application Fee: {naira} {calculateApplication?.toLocaleString()}
          </h2>
          <hr />
          <h2>
            Subscription Cost: {naira} {calculateSubsription?.toLocaleString()}
          </h2>
          <hr />
          <h2>
            Total Payable: {naira} {calculatePayable?.toLocaleString()}
          </h2>
          <hr />
          <h2>
            Amount Paid: {naira} {calculatePaid?.toLocaleString()}
          </h2>
          <hr />
          <h2>
            Outstanding: {naira} {calculateOutstanding?.toLocaleString()}
          </h2> */}
          <hr />
          <ExcelExport
            className="btn btn-info"
            table="emp-table"
            sheet="Sheet"
            buttonText="export to excel"
            filename="Suscribed Schools"
          />
        </section>
      </main>
      {/* <!-- End #main --> */}
    </>
  );
}

export default SuscribedSchools;
