import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import styled from "styled-components";
import API_URL from "../../config";
import axios from "axios";
import { Button, Form, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
function HomePage() {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [services, setServices] = useState(null);
  // const url = `${API_URL}/product`;
  const url = API_URL + "/product";
  useEffect(() => {
    setLoading(true);
    axios.get(url).then(({ data }) => {
      setProduct(data?.payload);
      // console.log(data)
      setLoading(false);
    });
  }, [url]);
  const url2 = API_URL + "/services";
  useEffect(() => {
    setLoading(true);
    axios.get(url2).then(({ data }) => {
      setServices(data?.payload);
      // console.log(data)
      setLoading(false);
    });
  }, [url2]);

  var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  if (format.test("davidson*")) {
    // return true;
    console.log("true")
  } else {
    // return false;
    console.log("false")
  }

  function checkUppercase(str) {
    for (var i = 0; i < str.length; i++) {
      if (
        str.charAt(i) == str.charAt(i).toUpperCase() &&
        str.charAt(i).match(/[a-z]/i)
      ) {
        return true;
      }
    }
    return false;
  }

  console.log(checkUppercase("im davidsonjose"))

  let str = "hello1";
  if (/\d/.test(str)) console.log(str + " has a number.");
  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>TIIASTECH SOLUTIONS</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile">
          {loading ? (
            <div className="mt-5 pt-5">
              <Spinner
                animation="border"
                className="text-center offset-md-6"
                role="status"
              >
                <span className="visually-hidden">loading.....</span>
              </Spinner>
            </div>
          ) : (
            <>
              <h3>PRODUCT LIST</h3>
              <div className="divider"></div>
              <div className="row">
                {product?.map((data) => (
                  <div className="col-md-4 pt-3">
                    <img
                      style={{ width: "200px", height: "200px" }}
                      src={data?.image}
                    />
                    <div>
                      <p className="pt-2">{data?.name}</p>
                      <Button variant="primary">
                        <Link
                          to={`/product/view/${data?.id}`}
                          className="text-white"
                        >
                          View
                        </Link>
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </section>

        <section className="section profile">
          <h3>SERVICES LIST</h3>
          <div className="divider"></div>
          <ServicesContainer className="pt-4">
            <div className="row">
              {services?.map((data) => (
                <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                  <div className="icon-box">
                    <div className="icon">
                      <i className="bx bxl-dribbble"></i>
                    </div>
                    <h4>
                      <Link to={`/services/view/${data?.id}`}>
                        {data?.name}
                      </Link>
                    </h4>
                    <p>{data?.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </ServicesContainer>
        </section>
      </main>
    </>
  );
}

const ServicesContainer = styled.div`
  .icon-box {
    text-align: center;
    background: #18d26e;
    border-color: #18d26e;
    padding: 80px 20px;
    transition: all ease-in-out 0.3s;
  }
  .icon-box .icon {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    background: #18d26e;
    border-radius: 5px;
    transition: all 0.3s ease-out 0s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transform-style: preserve-3d;
  }
  .icon-box .icon i {
    color: #fff;
    font-size: 28px;
  }
  .icon-box .icon::before {
    position: absolute;
    content: "";
    left: -8px;
    top: -8px;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 5px;
    transition: all 0.3s ease-out 0s;
    transform: translateZ(-1px);
  }
  .icon-box h4 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
  }
  .icon-box h4 a {
    color: #fff;
  }
  .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  .icon-box {
    background: #18d26e;
    border-color: #18d26e;
  }
  .icon-box .icon {
    background: #fff;
  }
  .icon-box .icon i {
    color: #18d26e;
  }
  .icon-box:hover .icon::before {
    background: #35e888;
  }
  .icon-box h4 a,
  .icon-box p {
    color: #fff;
  }
`;

export default HomePage;
