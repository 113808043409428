import React, { useState, useEffect } from "react";
// import { handleMoving } from "../handles";
import Header from "../../../Header";
import Sidebar from "../../../Sidebar";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import API_URL from "../../../../../config/index";
import { Button, Form, Spinner } from "react-bootstrap";
import { getToken } from "../../../../../utils/Common";
import axios from "axios";

// import "../../../App.css"
// import "./style.css"
function CreateVisitJosrac() {
  const [selectpackage, setSelectPackage] = useState("");
  const [selectpayment, setSelectPayment] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [select, setSelect] = useState(null)
  const [select2, setSelect2] = useState(null)
  const [message, setMessage] = useState(null);
  const [show, setShow] = useState(false);
  const [err, setErr] = useState(null);
  const [suscribedstatus, setSuscribedStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    nameofschool: "",
    schoolstatus: "",
    activationfee: "",
    subscriptioncost: "",
    totalpayable: "",
    amountpaid: "",
    // outstanding: "",
    paymentdescription: "",
    modeofpayment: "",
    paymentdate: "",
    remark: "",
    session: "",
    package: "",
  });

  const url = `${API_URL}/product/suscribed/create/${id}`;

  // const token = getToken();
  // const axiosCreate = axios.create({
  //   headers: {
  //     authorization: "Bearer " + token,
  //   },
  // });

  const handleSubmit = (e) => {
    // const formData = new FormData();
    // console.log(image)
    // formData.append("title", data?.title);
    e.preventDefault();
    setShow(false);
    setLoading(true);
    axios
      .post(url, {
        nameofschool: location?.state?.nameofschool,
        schoolstatus: suscribedstatus,
        activationfee: data?.activationfee,
        subscriptioncost: data.subscriptioncost,
        totalpayable: data.totalpayable,
        amountpaid: data.amountpaid,
        // outstanding: data.outstanding,
        paymentdescription: data.paymentdescription,
        modeofpayment: select2 === "Other" ? selectpayment : select2,
        paymentdate: data.paymentdate,
        remark: data.remark,
        session: data.session,
        package: select === "Other" ? selectpackage : select,
        registeredId: location?.state?.id 
      })
      .then(({ data }) => {
        setMessage(data?.message);
        setLoading(false);
      })
      .catch((err) => {
        setShow(true)
        setErr(err?.response?.data?.message);
        console.log(err?.response);
        // console.log(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const handle = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
    console.log(newData);
  };

  const handleCategory = (e) => {
    e.preventDefault();
    setSuscribedStatus(e.target.value);
  };

  const handleSelect = (e) => {
    setSelect(e.target.value);
  };

  const handleSelect2 = (e) => {
    setSelect2(e.target.value);
  };

  const handleSelectPackage = (e) => {
    setSelectPackage(e.target.value);
  };

  const handleSelectPayment = (e) => {
    setSelectPayment(e.target.value);
  };


  // 2070487907 uba divine momodu princess....
  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Step 2</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">
                Create Suscribed School
              </li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profil table-responsive">
        <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Go back
          </button>
          {loading ? (
            <div className="text-center mt-5 pt-5">
              <Spinner animation="border" className="text-center" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              {err && show ? (
                <div
                  class="alert alert-danger text-center container w-50"
                  role="alert"
                >
                  <strong>{err}</strong>
                </div>
              ) : (
                <div style={{ display: "none" }}>{err}</div>
              )}

              {message ? (
                <div class="alert alert-success text-center " role="alert">
                  <strong>{message}</strong>
                  <p style={{ display: "none" }}>
                  {setTimeout(() => navigate(`/suscribed-schools/${id}`), 3000)}
                  </p>
                </div>
              ) : (
                <div></div>
              )}
              <h2 className="display-5 text-center m-5">
                Create Suscribed School Josrac
              </h2>
              <Form
                className="w-50 mx-auto"
                onSubmit={(e) => handleSubmit(e)}
                //   enctype="multipart/form-data"
                method="post"
                style={{ width: "50%", margin: "auto" }}
              >
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name of School</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="enter name of school"
                    className="w-100"
                    value={location?.state?.nameofschool}
                    onChange={(e) => handle(e)}
                    name="nameofschool"
                    // required
                    disabled
                  />
                </Form.Group>
                <Form.Select
                  aria-label="Default select example"
                  className="form-control mb-3"
                  onChange={(e) => handleCategory(e)}
                >
                  <option>select status</option>
                  <option
                    value="new"
                    name="schoolstatus"
                    onChange={(e) => handleCategory(e)}
                  >
                    {/* {data?.status} */}
                    New
                  </option>
                  <option
                    value="existing"
                    name="schoolstatus"
                    onChange={(e) => handleCategory(e)}
                  >
                    {/* {suscribedstatus} */}
                    Exisiting
                  </option>
                  <option
                    value="returning"
                    name="schoolstatus"
                    onChange={(e) => handle(e)}
                  >
                    {/* {data?.status} */}
                    Returning
                  </option>

                  {/* <option value="2" onChange={(e) => handle(e)} name="category">
                    Two
                  </option>
                  <option value="3" onChange={(e) => handle(e)} name="category">
                    Three
                  </option> */}
                </Form.Select>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Application FEE</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="enter application fee"
                    className="w-100"
                    value={data?.activationfee}
                    onChange={(e) => handle(e)}
                    name="activationfee"
                    // required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Suscription Cost / Learners</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="enter subscription cost"
                    className="w-100"
                    value={data?.subscriptioncost}
                    onChange={(e) => handle(e)}
                    name="subscriptioncost"
                    // required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Total Payable</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="enter Total Payable"
                    className="w-100"
                    value={data?.totalpayable}
                    onChange={(e) => handle(e)}
                    name="totalpayable"
                    // required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Amount Paid</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="enter Amount Paid"
                    className="w-100"
                    value={data?.amountpaid}
                    onChange={(e) => handle(e)}
                    name="amountpaid"
                    // required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Payment Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="enter Payment description"
                    className="w-100"
                    value={data?.paymentdescription}
                    onChange={(e) => handle(e)}
                    name="paymentdescription"
                    // required
                  />
                </Form.Group>

                <label for="Modeofpayment">Mode of Payment</label><br />
                <select
                    name="modeofpayment"
                    className="form-control"
                    value={select2}
                    onChange={(e) => handleSelect2(e)}
                    disabled={select2 === "Other"}
                  >
                    <option selected>
                      Select mode of payment
                    </option>
                    <option value="Cheque">
                      Cheque
                    </option>
                    <option value="Bank USSD">
                      Bank USSD
                    </option>
                    <option value="Cash">
                      Cash
                    </option>
                    <option value="Other">
                      Other
                    </option>
                  </select><br />
                  {select2 === "Other" && (
                    <>
                    <Form.Control
                      type="text"
                      placeholder="please fill in payment type"
                      className="w-50"
                      value={selectpayment}
                      onChange={(e) => handleSelectPayment(e)}
                      name="modeofpayment"
                      required
                    />
                    <Button variant="dark" onClick={()=> setSelect("")}>Cancel</Button><br ></br>
                    </>
                  )}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Payment Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="enter payment date"
                    className="w-100"
                    value={data?.paymentdate}
                    onChange={(e) => handle(e)}
                    name="paymentdate"
                    // required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Remark</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="enter Remark"
                    className="w-100"
                    value={data?.remark}
                    onChange={(e) => handle(e)}
                    name="remark"
                    // required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Session</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="session"
                    className="w-100"
                    value={data?.session}
                    onChange={(e) => handle(e)}
                    name="session"
                    // required
                  />
                </Form.Group>
                <label for="Package">Package</label>
                <select
                    name="package"
                    className="form-control"
                    value={select}
                    onChange={(e) => handleSelect(e)}
                    disabled={select === "Other"}
                  >
                    <option selected>
                      Select Package
                    </option>
                    <option value="Jpro">
                      Jpro
                    </option>
                    <option value="Jpremium">
                      Jpremium
                    </option>
                    <option value="Jbasic">
                      Jbasic
                    </option>
                    <option value="Other">
                      Other
                    </option>
                  </select><br />
                  {select === "Other" && (
                    <>
                    <Form.Control
                      type="text"
                      placeholder="please fill in package type field"
                      className="w-50"
                      value={selectpackage}
                      onChange={(e) => handleSelectPackage(e)}
                      name="package"
                      required
                    />
                    <Button variant="dark" onClick={()=> setSelect("")}>Cancel</Button><br ></br>
                    </>
                  )}
                <Button variant="primary" type="submit">
                  Create Suscribed School
                </Button>
                {/* <Button variant="outline-warning">Go Back</Button> */}
              </Form>
            </>
          )}
        </section>
      </main>
      {/* <!-- End #main --> */}
    </>
  );
}

export default CreateVisitJosrac;
